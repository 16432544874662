import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Subject } from 'rxjs';
import { MODAL_IDENTIFIERS } from '../../enums/enums';
import { Item } from 'src/app/core/models/common.model';
import { Action } from 'rxjs/internal/scheduler/Action';


@Component({
  selector: 'app-select-dialog',
  templateUrl: './select-dialog.component.html',
  styleUrls: ['./select-dialog.component.scss']
})

export class SelectDialogComponent {
  public MODAL_IDENTIFIERS = MODAL_IDENTIFIERS;
  public componentDestroyed$: Subject<boolean> = new Subject();
  public selected: any;
  public options: Array<any> = [];
  public dialogData: SelectDialogInput = {
    header: "Select",
    options: []
  };

  constructor(
    public _ngxSmartModal: NgxSmartModalService
  ) { }

  onOpenModal() {
    setTimeout(() => {
      this.dialogData = this._ngxSmartModal.getModal(MODAL_IDENTIFIERS.SELECT_POPUP).getData();
      this.selected = this.dialogData.options[0]?.value || this.options[0];
    });
  }


  closeModal() {
    this._ngxSmartModal.getModal(MODAL_IDENTIFIERS.SELECT_POPUP).onClose.emit({
      action: ActionResult.Cancel,
      data: null
    } as SelectDialogResult);
    this._ngxSmartModal.getModal(MODAL_IDENTIFIERS.SELECT_POPUP).close();
  }

  onSelectOption() {
    this._ngxSmartModal.getModal(MODAL_IDENTIFIERS.SELECT_POPUP).onClose.emit({
      action: ActionResult.Select,
      data: this.selected
    } as SelectDialogResult);
    this.closeModal();
  }
}

export interface SelectDialogInput {
  header: string;
  options: Array<any>;
  footer?: {
    selectLabel?: string,
    cancelLabel?: string
  }
}

export interface SelectDialogResult {
  action: string,
  data: any;
}

export enum ActionResult {
  Cancel = "Cancel",
  Select = "Select"
}
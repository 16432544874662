import { Component } from '@angular/core';
import { interval, Subject } from 'rxjs';
import { UnitConversionService } from 'src/app/core/services/unit-conversion.service';
import { UnitSystemsService } from 'src/app/core/services/unitsystems.service';
import { UtilitiesService } from 'src/app/core/services/utilitiesservice.service';
import { INTERVAL_TIMES, UNITS } from 'src/app/shared/enums/enums';
import { environment } from 'src/environments/environment';
import { SystemService } from 'src/app/core/services/system.service';
import { startWith, switchMap } from 'rxjs/operators';
import { LocalTokenService } from 'src/app/core/services/localtoken.service';
import { SettingsService } from 'src/app/core/services/settings.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  public unitsystem: string;
  public defaultSystemLabel: string;
  public jobUnitLabel: string;
  public globalUnitSystemLabel: string;
  public userReferLabel: string;
  public jobReferLabel: string;
  public referedUnit: string;
  public isLocal: boolean = false;
  componentDestroyed$: Subject<boolean> = new Subject();

  constructor(
    public _localauth: LocalTokenService,
    public _unitConversion: UnitConversionService,
    public _unitSystems: UnitSystemsService,
    public _utilities: UtilitiesService,
    public _system: SystemService,
    public _settings: SettingsService
  ) {
  }

  ngOnInit() {
    this.globalUnitSystemLabel = this._utilities.getTranslationNow('layout.globalcurrentUnitSystemLabel');
    this.userReferLabel = this._utilities.getTranslationNow('settings.uom_user_preferred');
    this.jobReferLabel = this._utilities.getTranslationNow('settings.uom_job_preferred');
    this._unitConversion.currentUnitSystem$.subscribe(
      result => {
        this.defaultSystemLabel = this.globalUnitSystemLabel;
        this.unitsystem = this._unitSystems.globalUnitSystem ? this._unitSystems.globalUnitSystem.name : UNITS.API ;
        this.referedUnit = this.userReferLabel;
        if(this._settings.settings.uomProfile == 'job') {
          this.unitsystem = this._unitSystems.jobUnitSystem ? this._unitSystems.jobUnitSystem.name : "";
          this.referedUnit = this.jobReferLabel;
        }
      },
      (err) => {
      }
    );

    this.isLocal = environment.local ? true : false;
    if (this.isLocal) {
      interval(INTERVAL_TIMES.TokenRefresh)
        .pipe(
          switchMap(() => this._localauth.getTokenGenerator(true))
        )
        .subscribe(res => {
          res;
        },
          error => {
          });

      interval(INTERVAL_TIMES.NetworkStatus)
        .pipe(
          startWith(0),
          switchMap(() => this._system.checkConnection())
        )
        .subscribe();
    };

  };

  get isOnInternet(): boolean {
    return environment.web.isOnInternet;
  }

  get isOnNetwork(): boolean {
    return environment.web.isOnNetwork;
  }

}

import 'rxjs/add/operator/map';
import { ApiService } from './api.service';
import { ColumnSettingsInfo } from './columnSettings';
import { Config } from './config'
import { CURRENT_UNITS } from 'src/app/utils/constants';
import { environment } from 'src/environments/environment';
import { FieldLevelUoMSettings } from 'src/app/shared/models/columnSettings';
import { FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';
import { JobService } from 'src/app/job/job.service';
import { Observable, Subject } from 'rxjs';
import { SAVE_DATA } from 'src/app/utils/constants';
import { takeUntil } from 'rxjs/operators';
import { UnitConversionService } from './unit-conversion.service';
import { UtilitiesService } from './utilitiesservice.service';
import { UUID } from 'angular2-uuid';
import * as LS from 'src/app/utils/localstorage';
import * as Models from 'src/app/shared/models/rigs.model';
import * as OB from "src/app/utils/objecter";
import { API_RESPONSE } from 'src/app/shared/enums/enums';

@Injectable({
  providedIn: "root",
})

export class RigsService {
  // catalogs
  public catPitTypes: Array<string> = [];
  public catPumps: Array<any> = [];
  public catSurfaceEquipment: Array<any> = [];
  public catSurfaceEquipmentTypes: Array<string> = [];
  public catSolidsControlEquipment: Array<any> = [];
  public catSolidsManufacturers: Array<string> = [];
  public catSolidsModels: Array<string> = [];
  public catSolidsStyles: Array<string> = [];
  public catSolidsScreens: Array<string> = [];
  public catPumpsManufacturers: Array<string> = [];
  public catPumpsModels: Array<string> = [];
  public catPumpsTypes: Array<string> = [];

  // job data
  public jobIdString: string;
  public rigIdString: string;
  public rigUtilizationIdString: string;

  // rig data
  public rigData: Models.Rig;
  //public rigDataConverted: Models.Rig;
  public rigDataStringInit: string;
  public rigDataStringFinal: string;

  // pits
  public pitsData: Array<Models.Pit> = [];
  //public pitsDataConverted: Array<Models.Pit> = [];
  public pitsDataStringInit: string;
  public pitsDataStringFinal: string;

  // solids control equipment
  public solidsData: Array<Models.SolidsControlEquipment> = [];
  public solidsIdString: string;

  // surface equipment
  public surfaceData: Models.SurfaceEquipment;
  //public surfaceDataConverted: Models.SurfaceEquipment;
  public surfaceDataStringInit: string;
  public surfaceDataStringFinal: string;

  // service lines
  public serviceData: Models.ServiceLine = null;
  //public serviceDataConverted: Models.ServiceLine = null;
  public serviceDataStringInit: string;
  public serviceDataStringFinal: string;

  // pumps
  public pumpsData: Array<Models.Pump> = [];
  //public pumpsDataConverted: Array<Models.Pump> = [];
  public pumpsDataStringInit: string;
  public pumpsDataStringFinal: string;

  // mudlift
  public mudliftData: Models.MudLiftPump = null;
  //public mudliftDataConverted: Models.MudLiftPump = null;
  public mudliftDataStringInit: string;
  public mudliftDataStringFinal: string;

  public rigProfileForm: FormGroup;
  public rigProfileError: boolean = false;
  public componentDestroyed$: Subject<boolean> = new Subject();

  constructor(
    public api: ApiService,
    public _job: JobService,
    public _utilities: UtilitiesService,
    public _unitConversion: UnitConversionService,
  ) {
    this.onChangeUnitSystem();
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  onChangeUnitSystem() {
    this._unitConversion.currentUnitSystem$.pipe(takeUntil(this.componentDestroyed$)).subscribe(
      result => {
        if (result.name) {
          // remove existing catalog data to force reload
          var savedunits = LS.getLocalStorageString(LS.LOCAL_STORAGE_KEY.Catalogs.UnitSystem.UnitSystemName);
          var currentunits = CURRENT_UNITS.Name;
          if (currentunits != savedunits) {
            this.catPumps = [];
            this.catSurfaceEquipment = [];
            this.catSolidsControlEquipment = [];
            LS.setLocalStorageString(LS.LOCAL_STORAGE_KEY.Catalogs.UnitSystem.UnitSystemName, currentunits);
          }
        }
      });
  }

  public async clonePumps(newPumps: Array<Models.Pump>) {
    newPumps = newPumps || [];
    let promises: Promise<any>[] = []

    for (const pump of newPumps) {
      pump.rigId = this.rigIdString;
      pump.rigUtilizationId = this.rigUtilizationIdString;
      pump.mudPumpWITSML.mudPumpId = UUID.UUID();
      promises.push(this.postPump(pump));
    }

    if (this.pumpsData) {
      for (const pump of this.pumpsData) {
        promises.push(this.deletePump(pump));
      }
    }

    return Promise.all(promises)
      .then((values) => {
        let err = values.find(x => x && x != API_RESPONSE.SUCCESS);
        if (err) {
          return err
        }
        newPumps = this.sortPumps(newPumps);
        this.pumpsData = newPumps;

        LS.setLocalStorage(LS.LOCAL_STORAGE_KEY.Data.Rig.Pumps, JSON.stringify(newPumps));
        return API_RESPONSE.SUCCESS;

      })
      .catch(err => {
        return err
      });
  }

  public async clonePits(newPits: Array<Models.Pit>) {
    newPits = newPits || [];

    let promises: Promise<any>[] = []

    if (this.pitsData) {
      for (const pit of this.pitsData) {
        promises.push(this.deletePit(pit));
      }
    }

    for (const pit of newPits) {
      pit.rigId = this.rigIdString;
      pit.rigUtilizationId = this.rigUtilizationIdString;
      pit.pitWITSML.pitId = UUID.UUID();
      promises.push(this.postPit(pit));
    }

    return Promise.all(promises)
      .then((values) => {
        let err = values.find(x => x && x != API_RESPONSE.SUCCESS);
        if (err) {
          return err;
        }
        newPits = this.sortPits(newPits);

        this.pitsData = newPits;
        localStorage.setItem(LS.LOCAL_STORAGE_KEY.Data.Rig.Pits, JSON.stringify(newPits));
        return API_RESPONSE.SUCCESS;
      })
      .catch(err => {
        return err;
      });
  }

  public async cloneSolids(newSolids: Array<Models.SolidsControlEquipment>) {
    newSolids = newSolids || [];
    let promises: Promise<any>[] = []
    let convertedSolids: Array<Models.SolidsControlEquipment> = [];

    if (this.solidsData) {
      for (const solids of this.solidsData) {
        promises.push(this.deleteSolidsControlEquipment(solids));
      }
    }
    const solidsStyles = { centrifuge: 'Centrifuge', degasser: 'Degasser', desander: 'Desander', desilter: 'Desilter', mudCleaner: 'Mud Cleaner', mudcleaner: 'MudCleaner', shaker: 'Shaker' };

    for (const solids of newSolids) {
      solids.rigId = this.rigIdString;
      solids.solidsControlEquipmentId = UUID.UUID();
      solids.rigUtilizationId = this.rigUtilizationIdString;

      switch (solids.style) {
        case solidsStyles.centrifuge:
          solids.centrifugeWITSML.centrifugeId = UUID.UUID();
          break;
        case solidsStyles.degasser:
          solids.degasserWITSML.degasserId = UUID.UUID();
          break;
        case solidsStyles.desander:
          solids.hydrocycloneWITSML.hydrocycloneId = UUID.UUID();
          break;
        case solidsStyles.desilter:
          solids.hydrocycloneWITSML.hydrocycloneId = UUID.UUID();
          break;
        case solidsStyles.mudcleaner:
        case solidsStyles.mudCleaner:
          solids.mudCleanerId = UUID.UUID();
          break;
        case solidsStyles.shaker:
          solids.shakerWITSML.shakerId = UUID.UUID();
          break;
      }
      promises.push(this.postClonedSolidsControlEquipment(solids));
      convertedSolids.push(solids);
    }

    return Promise.all(promises)
      .then((values) => {
        let err = values.find(x => x && x != API_RESPONSE.SUCCESS);
        if (err) {
          return err;
        }
        this.loadClonedSolidsControlEquipment(convertedSolids);
        return API_RESPONSE.SUCCESS;
      })
      .catch(err => {
        return err;
      });
  }

  async loadCatalogPitType(forceLoad: boolean) {
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.Rig.PitTypes;
    let data = (!forceLoad) ? LS.getLocalStorage(key) : null;
    if (data) {
      this.catPitTypes = data;
      //return "Success - from cache";
    }
    else {
      var url = Config.APIUrlCatalogs + environment.catalogs.rigs.pitStyles;
      return await this.api.get<string[]>(url).toPromise()
        .then(data => {
          this._utilities.storeCatalogs(key, data);
          this.catPitTypes = data;
          return data;
          //return API_RESPONSE.SUCCESS;
        })
        .catch(err => {
          return err;
        });
    }
    return data;
  }

  async loadCatalogSurfaceEquipment(forceLoad: boolean) {
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.Rig.SurfaceEquipmentType;
    let data = (!forceLoad) ? LS.getLocalStorage(key) : null;
    if (data) {
      //this._utilities.formatData_CurrentUnitSystem(data, ColumnSettingsInfo.surfaceEquipment, Array("surfaceEquipment_id", "surfaceEquipment_length"), false);
      this.convertCatalogSurfaceEquipment(data, ColumnSettingsInfo.surfaceEquipment);
      this.catSurfaceEquipment = data;
      this.catSurfaceEquipmentTypes = data.map((c) => c.surfaceEquipmentWITSML.typeSurfEquip).sort();
      //return "Success - from cache";
    }
    else {
      var url = Config.APIUrlCatalogs + environment.catalogs.rigs.surfaceEquipmentType;
      return await this.api.get<Array<any>>(url).toPromise()
        .then(data => {
          this._utilities.storeCatalogs(key, data);
          this.convertCatalogSurfaceEquipment(data, ColumnSettingsInfo.surfaceEquipment);
          this.catSurfaceEquipment = data;
          this.catSurfaceEquipmentTypes = data.map((c) => c.surfaceEquipmentWITSML.typeSurfEquip).sort();
          return data;
          //return API_RESPONSE.SUCCESS;
        })
        .catch(err => {
          return err;
        });
    }
    return data;
  }

  async loadCatalogPump(forceLoad: boolean) {
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.Rig.Pumps;
    let data = (!forceLoad) ? LS.getLocalStorage(key) : null;
    if (data) {
      this.catPumps = data;
      this.convertCatalogPumps(data, ColumnSettingsInfo.mudPumps);
      //return "Success - from cache";
    }
    else {
      var url = Config.APIUrlCatalogs + environment.catalogs.rigs.pumps;
      url = url.replace("?pageNumber={pageNum}", "");
      return await this.api.get<Array<any>>(url).toPromise()
        .then(data => {
          data = this.sortPumps(data);
          data = data.map(x => x.mudPumpWITSML); // flatten for easier table access
          // var sorted = data.sort((a, b) =>
          //   (a.typePump > b.typePump) ? 1 :
          //     (a.typePump === b.typePump) ? ((a.model > b.model) ? 1 : -1) : -1);
          this._utilities.storeCatalogs(key, data);
          this.catPumps = data;
          this.convertCatalogPumps(this.catPumps, ColumnSettingsInfo.mudPumps);
          this.loadCatalogPumpsProperties(true);
          return data;
          //return API_RESPONSE.SUCCESS;
        })
        .catch(err => {
          return err;
        });
    }
    return data;
  }

  async loadCatalogPumpType(forceLoad: boolean) {
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.Rig.Pumps_Types;
    let data = (!forceLoad) ? LS.getLocalStorage(key) : null;
    if (data) {
      this.catPumpsTypes = data;
      //return "Success - from cache";
    }
    else {
      var url = Config.APIUrlCatalogs + environment.catalogs.rigs.pumpTypes;
      return await this.api.get<string[]>(url).toPromise()
        .then(data => {
          this._utilities.storeCatalogs(key, data);
          this.catPumpsTypes = data;
          return data;
        })
        .catch(err => {
          return err;
        });
    }
    return data;
  }

  async loadCatalogSolidsControlEquipment(forceLoad: boolean) {
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.Rig.SolidsControlEquipment;
    let data = (!forceLoad) ? LS.getLocalStorage(key) : null;
    if (data) {
      this.catSolidsControlEquipment = data;
      this.convertCatalogSolids(data, ColumnSettingsInfo.solidsControlEquipment);
      this.loadCatalogSolidsProperties(true);
      //return "Success - from cache";
    }
    else {
      var url = Config.APIUrlCatalogs + environment.catalogs.rigs.solids;
      url = url.replace("?pageNumber={pageNum}", "");
      return await this.api.get<Array<Models.SolidsControlEquipment>>(url).toPromise()
        .then(data => {
          data.forEach(x => { if (x.style === "Conditioner") x.style = "MudCleaner" });
          var sorted = data.sort((a, b) =>
            (a.style > b.style) ? 1 :
              (a.style === b.style) ? ((a.manufacturer > b.manufacturer) ? 1 :
                (a.manufacturer === b.manufacturer) ? ((a.model > b.model) ? 1 : -1) : -1) : -1);
          this._utilities.storeCatalogs(key, sorted);
          this.catSolidsControlEquipment = sorted;
          this.convertCatalogSolids(sorted, ColumnSettingsInfo.solidsControlEquipment);
          this.loadCatalogSolidsProperties(true);
          return data;
          //return API_RESPONSE.SUCCESS;
        })
        .catch(err => {
          return err;
        });
    }
    return data;
  }

  async loadCatalogScreenType(forceLoad: boolean) {
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.Rig.Solids_Screens;
    if (!forceLoad) { var data = LS.getLocalStorage(key); }
    if (data) {
      this.catSolidsScreens = data;
      //return "Success - from cache";
    }
    else {
      var url = Config.APIUrlCatalogs + environment.catalogs.rigs.screenTypes;
      return await this.api.get<string[]>(url).toPromise()
        .then(data => {
          this._utilities.storeCatalogs(key, data);
          this.catSolidsScreens = data;
          return data;
          //return API_RESPONSE.SUCCESS;
        })
        .catch(err => {
          return err;
        });
    }
    return data;
  }

  async loadCatalogPumpsProperties(forceLoad: boolean) {
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.Rig.Pumps_Properties;
    let data = (!forceLoad) ? LS.getLocalStorage(key) : null;
    if (data) {
      this.catPumpsManufacturers = LS.getLocalStorage(LS.LOCAL_STORAGE_KEY.Catalogs.Rig.Pumps_Manufacturers);
      this.catPumpsModels = LS.getLocalStorage(LS.LOCAL_STORAGE_KEY.Catalogs.Rig.Pumps_Models);
      //this.catPumpsTypes = LS.getLocalStorage(LS.LOCAL_STORAGE_KEY.Catalogs.Rig.Pumps_Types);
    }
    else {
      var items = LS.getLocalStorage(LS.LOCAL_STORAGE_KEY.Catalogs.Rig.Pumps);
      var values: string[] = items.map(x => x.model);
      values.push("");
      //var distinctvalues = values.filter((n, i) => values.indexOf(n) === i).sort();
      var distinctvalues = Array.from(new Set(values)).sort();
      this._utilities.storeCatalogs(LS.LOCAL_STORAGE_KEY.Catalogs.Rig.Pumps_Models, distinctvalues);
      this.catPumpsModels = distinctvalues;
      this._utilities.storeCatalogs(key, "true");
    }
  }

  async loadCatalogSolidsProperties(forceLoad: boolean) {
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.Rig.Solids_Properties;
    let data = (!forceLoad) ? LS.getLocalStorage(key) : null;
    if (data) {
      this.catSolidsManufacturers = LS.getLocalStorage(LS.LOCAL_STORAGE_KEY.Catalogs.Rig.Solids_Manufacturers);
      this.catSolidsModels = LS.getLocalStorage(LS.LOCAL_STORAGE_KEY.Catalogs.Rig.Solids_Models);
      this.catSolidsStyles = LS.getLocalStorage(LS.LOCAL_STORAGE_KEY.Catalogs.Rig.Solids_Styles);
      return "Success - from cache";
      //this.catSolidsScreens = LS.getLocalStorage(LS.LOCAL_STORAGE_KEY.Catalogs.Rig.Solids_Screens);
    }
    else {
      var items = LS.getLocalStorage(LS.LOCAL_STORAGE_KEY.Catalogs.Rig.SolidsControlEquipment);
      //var items = JSON.parse(base);

      var values: string[] = items.map(x => x.manufacturer);
      values.push("");
      //var distinctvalues = values.filter((n, i) => values.indexOf(n) === i).sort();
      var distinctvalues = Array.from(new Set(values)).sort();
      this._utilities.storeCatalogs(LS.LOCAL_STORAGE_KEY.Catalogs.Rig.Solids_Manufacturers, distinctvalues);
      this.catSolidsManufacturers = distinctvalues;

      var values: string[] = items.map(x => x.model);
      values.push("");
      //var distinctvalues = values.filter((n, i) => values.indexOf(n) === i).sort();
      var distinctvalues = Array.from(new Set(values)).sort();
      this._utilities.storeCatalogs(LS.LOCAL_STORAGE_KEY.Catalogs.Rig.Solids_Models, distinctvalues);
      this.catSolidsModels = distinctvalues;

      var values: string[] = items.map(x => x.style);
      //var values = items.map(x => x.style.replace("Conditioner", "MudCleaner"));
      //var distinctvalues = values.filter((n, i) => values.indexOf(n) === i);
      var distinctvalues = Array.from(new Set(values));
      distinctvalues = distinctvalues.map(x => x === "Conditioner" ? "MudCleaner" : x).sort();
      distinctvalues = distinctvalues.filter(function (value) { return value.toLocaleLowerCase() != "othersce"; });
      this._utilities.storeCatalogs(LS.LOCAL_STORAGE_KEY.Catalogs.Rig.Solids_Styles, distinctvalues);
      this.catSolidsStyles = distinctvalues;

      // var values: string[] = items.map(x => x.screenLayout);
      // values.push("");
      // var distinctvalues = values.filter((n, i) => values.indexOf(n) === i).sort();
      // this._utilities.storeCatalogs(LS.LOCAL_STORAGE_KEY.Catalogs.Rig.Solids_Screens, distinctvalues);
      // this.catSolidsScreens = distinctvalues;

      this._utilities.storeCatalogs(key, "true");
    }
  }

  public async loadRigCatalogs(forceLoad: boolean) {
    forceLoad = this._utilities.checkCatalogExpiration(forceLoad);
    var promises = [];
    // skip load when memory data is present and not forced reload, i.e. as on subsequent page loads
    (!forceLoad && this.catPumps && (this.catPumps.length > 0)) || promises.push(this.loadCatalogPump(forceLoad));
    (!forceLoad && this.catSolidsControlEquipment && (this.catSolidsControlEquipment.length > 0)) || promises.push(this.loadCatalogSolidsControlEquipment(forceLoad));
    (!forceLoad && this.catSurfaceEquipment && (this.catSurfaceEquipment.length > 0)) || promises.push(this.loadCatalogSurfaceEquipment(forceLoad));
    (!forceLoad && this.catSolidsScreens && (this.catSolidsScreens.length > 0)) || promises.push(this.loadCatalogScreenType(forceLoad));
    (!forceLoad && this.catPitTypes && (this.catPitTypes.length > 0)) || promises.push(this.loadCatalogPitType(forceLoad));
    (!forceLoad && this.catPumpsTypes && (this.catPumpsTypes.length > 0)) || promises.push(this.loadCatalogPumpType(forceLoad));

    return Promise.all(promises)
      .then(resp => {
        console.log("Rig catalogs loaded");
        let errs = this._utilities.checkPromiseErrors(resp);
        if (errs > 0) return null;
        return resp;
      })
      .catch(() => {
        console.log("Rig catalogs error");
        return null;
      });
  }

  public async loadRigPacket(rigId: string, forceLoad: boolean) {
    var key = LS.LOCAL_STORAGE_KEY.Data.Rig.Packet;
    let data = (!forceLoad) ? LS.getLocalStorage(key) : null;
    if (data) {
      return "Success - from cache";
    }
    else {
      var url = Config.APIUrlCore + environment.rigPackets.getRigByRigId.replace("{rigId}", rigId);
      return await this.api.get<Models.Rig>(url).toPromise()
        .then(data => {
          return API_RESPONSE.SUCCESS;
        })
        .catch(err => {
          return "Default";
        });
    }
  }

  public getRig(jobId: string) {
    var url = Config.APIUrlCore + environment.rigs.get.replace("{jobId}", jobId);
    return new Observable(ob => {
      var cached = LS.getLocalStorage(LS.LOCAL_STORAGE_KEY.Data.Rig.Rig);
      if (cached) {
        ob.next(cached);
        ob.complete();
        return;
      }
      this.api.get<any[]>(url).subscribe(
        (res) => {
          ob.next(res);
          ob.complete();
        },
        (error) => {
          ob.next(null);
          ob.complete();
        }
      );
    });
  }

  public async loadRig(jobId: string, forceLoad: boolean) {
    var key = LS.LOCAL_STORAGE_KEY.Data.Rig.Rig;
    let data = (!forceLoad) ? LS.getLocalStorage(key) : null;
    if (data) {
      this.rigData = data;
      this.rigIdString = this.rigData.rigWITSML.rigId;
      this.rigUtilizationIdString = this.rigData.rigUtilization.rigUtilizationWITSML.rigUtilizationId;
      this.solidsIdString = this.rigData.rigUtilization.rigUtilizationWITSML.solidsControlEquipment.solidsControlEquipmentId;
      this.rigDataStringInit = JSON.stringify(data);
      return "Success - from cache";
    }
    else {
      var url = Config.APIUrlCore + environment.rigs.get.replace("{jobId}", jobId);
      return await this.api.get<Models.Rig>(url).toPromise()
        .then(data => {
          // spread returned data over new instance to ensure all properties
          //var gap = data.rigUtilization.rigUtilizationWITSML.airGap;
          //delete data.rigUtilization.rigUtilizationWITSML.airGap;
          var def = new Models.Rig();
          data = { ...def, ...data };
          OB.updateObj(data, "rigUtilization.rigUtilizationWITSML.rigUtilizationId", data.rigWITSML.rigId);
          OB.updateObj(data, "rigUtilization.rigUtilizationWITSML.solidsControlEquipment.solidsControlEquipmentId", data.rigWITSML.rigId);
          LS.setLocalStorage(key, data)
          this.rigData = data;
          this.rigIdString = this.rigData.rigWITSML.rigId;
          this.rigUtilizationIdString = this.rigData.rigUtilization.rigUtilizationWITSML.rigUtilizationId;
          this.solidsIdString = this.rigData?.rigUtilization?.rigUtilizationWITSML?.solidsControlEquipment?.solidsControlEquipmentId || "";
          this.rigDataStringInit = JSON.stringify(data);
          return API_RESPONSE.SUCCESS;
        })
        .catch(async err => {
          // if api call for existing rig fails, create new rig
          let obj = new Models.Rig();
          obj.jobId = jobId;
          obj.rigWITSML.owner = "Baroid";
          obj.rigWITSML.title = "New rig";
          obj.rigWITSML.rigId = UUID.UUID();
          obj.rigUtilization.rigUtilizationWITSML.rigUtilizationId = obj.rigWITSML.rigId;
          obj.rigUtilization.rigUtilizationWITSML.solidsControlEquipment.solidsControlEquipmentId = obj.rigWITSML.rigId;
          this.rigDataStringInit = JSON.stringify(obj);
          await this.postRig(obj);
          return "Default";
        });
    }
  }

  // create/update
  public async postRig(obj: Models.Rig) {
    var url = Config.APIUrlCore + environment.rigs.post;
    return await this.api.post<Models.Rig>(url, obj).toPromise()
      .then(resp => {
        this.rigDataStringInit = JSON.stringify(obj);
        this.rigData = obj;
        this.rigIdString = this.rigData.rigWITSML.rigId;
        this.rigUtilizationIdString = this.rigData.rigUtilization.rigUtilizationWITSML.rigUtilizationId;
        this.solidsIdString = this.rigData.rigUtilization.rigUtilizationWITSML.solidsControlEquipment.solidsControlEquipmentId;
        LS.setLocalStorage(LS.LOCAL_STORAGE_KEY.Data.Rig.Rig, obj);
        if (SAVE_DATA.ClearCache) {
          //this._utilities.LS.clearJobLocalStorage();
        }
        return API_RESPONSE.SUCCESS;
      })
      .catch(err => {
        return err;
      });
  }

  // pits
  // public async getAllPits() {
  //   var url = Config.APIUrlCatalogs + environment.pits.get;
  //   return await this.api.get<any>(url).pipe(take(1)).toPromise().catch(err => '');
  // }

  public sortPits(data: Array<Models.Pit>): Array<Models.Pit> {
    var sorted = data.sort((a, b) =>
      (a.pitName > b.pitName) ? 1 : -1);
    return sorted;
  }

  public async loadPits(rigId: string, forceLoad: boolean) {
    var key = LS.LOCAL_STORAGE_KEY.Data.Rig.Pits;
    // we need the string and the data
    let data = (!forceLoad) ? LS.getLocalStorageString(key) : null;
    if (data) {
      this.pitsDataStringInit = data;
      this.pitsData = JSON.parse(data);
      return "Success - from cache";
    }
    else {
      var url = Config.APIUrlCore + environment.pits.getByRigId;
      return await this.api.get<Array<Models.Pit>>(url.replace("{rigId}", rigId)).toPromise()
        .then(data => {
          data = data.map(obj => {
            // spread returned data over new instance to ensure all properties
            var def = new Models.Pit();
            obj = { ...def, ...obj };
            return obj;
          });
          data = this.sortPits(data);
          this.pitsData = data;
          this.pitsDataStringInit = JSON.stringify(this.pitsData);
          LS.setLocalStorageString(key, this.pitsDataStringInit)
          return API_RESPONSE.SUCCESS;
        })
        .catch(err => {
          this.pitsData = [];
          this.pitsDataStringInit = '[]';
          return err;
        },
        );
    }
  }

  // create/update
  public async postPit(obj: Models.Pit) {
    var url = Config.APIUrlCore + environment.pits.post;
    return await this.api.post<Models.Pit>(url, obj).toPromise()
      .then(resp => {
        this.pitsDataStringInit = JSON.stringify(this.pitsData);
        return API_RESPONSE.SUCCESS;
      })
      .catch(err => {
        return err;
      });
  }

  // create/update many
  public async postPits(obj: Array<Models.Pit>, BatchModify: boolean) {
    if (BatchModify) {
      // batch
      var url = Config.APIUrlCore + environment.pits.postMany;
      return await this.api.post<Array<Models.Pit>>(url, obj).toPromise()
        .then(resp => {
          return API_RESPONSE.SUCCESS;
        })
        .catch(err => {
          return err;
        });
    }
    else {
      // single
      let promises = [];
      obj.forEach(pit => {
        promises.push(this.postPit(pit));
      });
      Promise.all(promises)
        .then((values) => {
          console.log(values)
        })
        .catch(err => {
          console.log("postPits error")
        });
    }
  }

  // delete
  public async deletePit(obj: Models.Pit) {
    return this.deletePitId(obj.pitWITSML.pitId);
  }
  public async deletePitId(id: string) {
    var url = Config.APIUrlCore + environment.pits.delete;
    return await this.api.post<Models.Pit>(url.replace("{Id}", id), null).toPromise()
      .then(resp => {
        return API_RESPONSE.SUCCESS;
      })
      .catch(err => {
        return err;
      });
  }

  public async deletePits(obj: Array<Models.Pit>, BatchModify: boolean) {
    if (BatchModify) {
      // batch
      var pits = obj.map(x => x.pitWITSML.pitId);
      var url = Config.APIUrlCore + environment.pits.deleteMany;
      return await this.api.post<Array<string>>(url, pits).toPromise()
        .then(resp => {
          return API_RESPONSE.SUCCESS;
        })
        .catch(err => {
          return err;
        });
    }
    else {
      // single
      let promises = [];
      obj.forEach(pit => {
        promises.push(this.deletePitId(pit.pitWITSML.pitId));
      });
      Promise.all(promises)
        .then((values) => {
          console.log(values)
        })
        .catch(err => {
          console.log("deletePits error")
        });
    }
  }

  public async getPitVolume(pit: Models.Pit) {
    let url = "";
    // get pitvolume endpoint
    // TODO, remove obsolete pit styles
    switch (pit.pitStyle) {
      case "HorizontalCylinder":
      case "HorizontalCylindrical":
      case "Horizontal Cylindrical":
        url = Config.APIUrlVolumes + environment.volumes.HorizontalCylindrical;
        break;
      case "HorizontalCylinderEllipticalEnds":
      case "HorizontalCylindricalEllipticalEnds":
      case "Horizontal Cylindrical Elliptical Ends":
        url = Config.APIUrlVolumes + environment.volumes.HorizontalCylindricalEllipticalEnds;
        break;
      case "HorizontalCylinderSphericalEnds":
      case "HorizontalCylindricalSphericalEnds":
      case "Horizontal Cylindrical Spherical Ends":
        url = Config.APIUrlVolumes + environment.volumes.HorizontalCylindricalSphericalEnds;
        break;
      case "Rectangular":
        url = Config.APIUrlVolumes + environment.volumes.Rectangular;
        break;
      case "VerticalCylinder":
      case "VerticalCylindrical":
      case "Vertical Cylindrical":
        url = Config.APIUrlVolumes + environment.volumes.VerticalCylindrical;
        break;
      case "VerticalCylinderConicalBottom":
      case "VerticalCylindricalConicalBottom":
      case "Vertical Cylindrical Conical Bottom":
        url = Config.APIUrlVolumes + environment.volumes.VerticalCylindricalConicBottom;
        break;
      case "VerticalCylindricalRoundBottom":
      case "VerticalCylinderRoundBottom":
      case "Vertical Cylindrical Round Bottom":
        url = Config.APIUrlVolumes + environment.volumes.VerticalCylindricalRoundBottom;
        break;
    }
    if (url != "") {
      let pitVolumeInput = this.mapPit2PitVolume(pit);
      pitVolumeInput.Depth.Value = 0;
      return await this.api.post<Models.PitVolumeInput>(url, pitVolumeInput).toPromise()
        .then(resp => {
          //var temp = Object.assign(new PitVolume(), resp);
          return resp;
        })
        .catch(err => {
          return err;
        });
    }
    return new Models.PitVolume();
  }

  // maps the values of pit object into active input
  mapPit2PitVolume(pit: Models.Pit): Models.PitVolumeInput {
    let obj = new Models.PitVolumeInput();
    obj.UseUllage = pit.useUllage;
    obj.Length.Value = pit.length.value;
    obj.Width.Value = pit.width.value;
    obj.Depth.Value = pit.depth.value;
    obj.Height.Value = pit.height.value;
    obj.SecondaryHeight.Value = pit.secondaryHeight.value;
    obj.Diameter.Value = pit.diameter.value;
    obj.SecondaryDiameter.Value = pit.secondaryDiameter.value;
    obj.Length.Uom = pit.length.uom;
    obj.Width.Uom = pit.width.uom;
    obj.Depth.Uom = pit.depth.uom;
    obj.Height.Uom = pit.height.uom;
    obj.SecondaryHeight.Uom = pit.secondaryHeight.uom;
    obj.Diameter.Uom = pit.diameter.uom;
    obj.SecondaryDiameter.Uom = pit.secondaryDiameter.uom;
    return obj;
  }

  // convert pitstyle between display format and enum format
  public convertPitstyle(style: string): string {
    let styles = {
      "Rectangular": "Rectangular",

      // dropdown text to enum (old)
      // "Horizontal Cylindrical": "HorizontalCylinder",
      // "Horizontal Cylindrical Elliptical Ends": "HorizontalCylinderEllipticalEnds",
      // "Horizontal Cylindrical Spherical Ends": "HorizontalCylinderSphericalEnds",
      // "Vertical Cylindrical": "VerticalCylinder",
      // "Vertical Cylindrical Conical Bottom": "VerticalCylinderConicalBottom",
      // "Vertical Cylindrical Round Bottom": "VerticalCylinderRoundBottom",

      // dropdown text to enum
      "Horizontal Cylindrical": "HorizontalCylindrical",
      "Horizontal Cylindrical Elliptical Ends": "HorizontalCylindricalEllipticalEnds",
      "Horizontal Cylindrical Spherical Ends": "HorizontalCylindricalSphericalEnds",
      "Vertical Cylindrical": "VerticalCylindrical",
      "Vertical Cylindrical Conical Bottom": "VerticalCylindricalConicalBottom",
      "Vertical Cylindrical Round Bottom": "VerticalCylinderRoundBottom",

      // enum to dropdown text (old)
      "HorizontalCylinder": "Horizontal Cylindrical",
      "HorizontalCylinderEllipticalEnds": "Horizontal Cylindrical Elliptical Ends",
      "HorizontalCylinderSphericalEnds": "Horizontal Cylindrical Spherical Ends",
      "VerticalCylinder": "Vertical Cylindrical",
      "VerticalCylinderConicalBottom": "Vertical Cylindrical Conical Bottom",
      "VerticalCylinderRoundBottom": "Vertical Cylindrical Round Bottom",

      // enum to dropdown text
      "HorizontalCylindrical": "Horizontal Cylindrical",
      "HorizontalCylindricalEllipticalEnds": "Horizontal Cylindrical Elliptical Ends",
      "HorizontalCylindricalSphericalEnds": "Horizontal Cylindrical Spherical Ends",
      "VerticalCylindrical": "Vertical Cylindrical",
      "VerticalCylindricalConicalBottom": "Vertical Cylindrical Conical Bottom",
      "VerticalCylindricalRoundBottom": "Vertical Cylindrical Round Bottom",
    };
    let convertedStyle = styles[style];
    return convertedStyle ? convertedStyle : style;
  }

  public async loadMudLiftPump(rigId: string, forceLoad: boolean) {
    var key = LS.LOCAL_STORAGE_KEY.Data.Rig.MudLiftPump;
    let data = (!forceLoad) ? LS.getLocalStorageString(key) : null;
    if (data) {
      this.mudliftDataStringInit = data;
      this.mudliftData = JSON.parse(data);
      return "Success - from cache";
    }
    else {
      var url = Config.APIUrlCore + environment.mudLiftPump.getByRigId;
      return await this.api.get<Models.MudLiftPump>(url.replace("{rigId}", rigId)).toPromise()
        .then(data => {
          // spread returned data over new instance to ensure all properties
          var def = new Models.MudLiftPump();
          data = { ...def, ...data };
          this.mudliftData = data;
          this.mudliftDataStringInit = JSON.stringify(data);
          LS.setLocalStorageString(key, this.mudliftDataStringInit)
          return API_RESPONSE.SUCCESS;
        })
        .catch(err => {
          let obj = new Models.MudLiftPump();
          obj.rigId = this.rigIdString;
          this.mudliftData = obj;
          this.mudliftData.rigId = this.rigIdString;
          this.mudliftData.rigUtilizationId = this.rigUtilizationIdString;
          obj = this.mudliftData;
          this.mudliftDataStringInit = JSON.stringify(obj);
          LS.setLocalStorageString(key, this.mudliftDataStringInit)
          return "Default";
        },
        );
    }
  }

  // create/update
  public async postMudlift(obj: Models.MudLiftPump) {
    var url = Config.APIUrlCore + environment.mudLiftPump.post;
    return await this.api.post<Models.MudLiftPump>(url, obj).toPromise()
      .then(resp => {
        this.mudliftDataStringInit = JSON.stringify(obj);
        LS.setLocalStorage(LS.LOCAL_STORAGE_KEY.Data.Rig.MudLiftPump, obj);
        return API_RESPONSE.SUCCESS;
      })
      .catch(err => {
        return err;
      });
  }

  // delete
  public async deleteMudlift(obj: Models.MudLiftPump) {
    var url = Config.APIUrlCore + environment.mudLiftPump.delete;
    return await this.api.post<Models.MudLiftPump>(url.replace("{Id}", obj.mudLiftPumpId), obj).toPromise()
      .then(resp => {
        LS.removeLocalStorage(LS.LOCAL_STORAGE_KEY.Data.Rig.MudLiftPump);
        return API_RESPONSE.SUCCESS;
      })
      .catch(err => {
        return err;
      });
  }

  public async loadServiceLine(rigId: string, forceLoad: boolean) {
    var key = LS.LOCAL_STORAGE_KEY.Data.Rig.ServiceLine;
    let data = (!forceLoad) ? LS.getLocalStorageString(key) : null;
    if (data) {
      this.serviceDataStringInit = data;
      this.serviceData = JSON.parse(data);
      return "Success - from cache";
    }
    else {
      var url = Config.APIUrlCore + environment.serviceLines.getByRigId;
      return await this.api.get<Models.ServiceLine>(url.replace("{rigId}", rigId)).toPromise()
        .then(data => {
          // spread returned data over new instance to ensure all properties
          var def = new Models.ServiceLine();
          data = { ...def, ...data };
          this.serviceData = data;
          this.serviceDataStringInit = JSON.stringify(data);
          LS.setLocalStorageString(key, this.serviceDataStringInit)
          return API_RESPONSE.SUCCESS;
        })
        .catch(err => {
          let obj = new Models.ServiceLine();
          obj.rigId = this.rigIdString;
          this.serviceData = obj;
          this.serviceData.rigId = this.rigIdString;
          this.serviceData.rigUtilizationId = this.rigUtilizationIdString;
          obj = this.serviceData;
          this.serviceDataStringInit = JSON.stringify(obj);
          LS.setLocalStorageString(key, this.serviceDataStringInit)
          return "Default";
        },
        );
    }
  }

  // create/update
  public async postServiceLine(obj: Models.ServiceLine) {
    var url = Config.APIUrlCore + environment.serviceLines.post;
    return await this.api.post<Models.ServiceLine>(url, obj).toPromise()
      .then(resp => {
        this.serviceDataStringInit = JSON.stringify(obj);
        LS.setLocalStorage(LS.LOCAL_STORAGE_KEY.Data.Rig.ServiceLine, obj);
        return API_RESPONSE.SUCCESS;
      })
      .catch(err => {
        return err;
      });
  }

  // delete
  public async deleteServiceLine(obj: Models.ServiceLine) {
    var url = Config.APIUrlCore + environment.serviceLines.delete;
    return await this.api.post<Models.ServiceLine>(url.replace("{Id}", obj.bopWITSML.bopId), obj).toPromise()
      .then(resp => {
        LS.removeLocalStorage(LS.LOCAL_STORAGE_KEY.Data.Rig.ServiceLine);
        return API_RESPONSE.SUCCESS;
      })
      .catch(err => {
        return err;
      });
  }

  public async loadSurfaceEquipment(rigId: string, forceLoad: boolean) {
    let key = LS.LOCAL_STORAGE_KEY.Data.Rig.SurfaceEquipment;
    let data = (!forceLoad) ? LS.getLocalStorageString(key) : null;
    if (data) {
      this.surfaceDataStringInit = data;
      this.surfaceData = JSON.parse(data);
      return "Success - from cache";
    }
    else {
      var url = Config.APIUrlCore + environment.surfaceEquipment.getByRigId;
      return await this.api.get<Models.SurfaceEquipment>(url.replace("{rigId}", rigId)).toPromise()
        .then(data => {
          // spread returned data over new instance to ensure all properties
          let def = new Models.SurfaceEquipment();
          data = { ...def, ...data };
          this.surfaceDataStringInit = JSON.stringify(data);
          this.surfaceData = data;
          LS.setLocalStorageString(key, this.surfaceDataStringInit);
          return API_RESPONSE.SUCCESS;
        })
        .catch(err => {
          let obj = new Models.SurfaceEquipment();
          obj.rigId = this.rigIdString;
          obj.surfaceEquipmentWITSML.typeSurfEquip = "unknown";
          this.surfaceData = obj;
          this.surfaceData.rigId = this.rigIdString;
          this.surfaceData.rigUtilizationId = this.rigUtilizationIdString;
          obj = this.surfaceData;
          this.surfaceDataStringInit = JSON.stringify(obj);
          LS.setLocalStorageString(key, this.surfaceDataStringInit)
          return "Default";
        },
        );
    }
  }

  // create/update
  public async postSurfaceEquipment(obj: Models.SurfaceEquipment) {
    var url = Config.APIUrlCore + environment.surfaceEquipment.post;
    return await this.api.post<Models.SurfaceEquipment>(url, obj).toPromise()
      .then(resp => {
        this.surfaceDataStringInit = JSON.stringify(obj);
        LS.setLocalStorage(LS.LOCAL_STORAGE_KEY.Data.Rig.SurfaceEquipment, obj);
        if (SAVE_DATA.ClearCache) {
          //this._utilities.LS.clearJobLocalStorage();
        }
        return API_RESPONSE.SUCCESS;
      })
      .catch(err => {
        return err;
      });
  }

  // delete
  public async deleteSurfaceEquipment(obj: Models.SurfaceEquipment) {
    var url = Config.APIUrlCore + environment.surfaceEquipment.delete;
    return await this.api.post<Models.SurfaceEquipment>(url.replace("{Id}", obj.surfaceEquipmentWITSML.surfaceEquipmentId), obj).toPromise()
      .then(resp => {
        LS.removeLocalStorage(LS.LOCAL_STORAGE_KEY.Data.Rig.SurfaceEquipment);
        return API_RESPONSE.SUCCESS;
      })
      .catch(err => {
        return err;
      });
  }

  public sortSolidsControlEquipment(data: Array<Models.SolidsControlEquipment>): Array<Models.SolidsControlEquipment> {
    var sorted = data.sort((a, b) =>
      (a.style > b.style) ? 1 :
        (a.style === b.style) ? ((a.manufacturer > b.manufacturer) ? 1 :
          (a.manufacturer === b.manufacturer) ? ((a.model > b.model) ? 1 : -1) : -1) : -1);
    return sorted;
  }

  public toSolidStyleData(val) {
    return val == "Degasser/Other" ? "Degasser" : val;
  }

  public toSolidStyleView(val) {
    return val == "Degasser" ? "Degasser/Other" : val;
  }

  public toSolidsView(data) {
    return data.map(x =>
      {
        x.style = this.toSolidStyleView(x.style);
        return x;
      });
  }

  public toSolidsData(data) {
    return data.map(x =>
      {
        x.style = this.toSolidStyleData(x.style);
        return x;
      });
  }

  public async loadSolidsControlEquipment(rigId: string, forceLoad: boolean) {
    var key = LS.LOCAL_STORAGE_KEY.Data.Rig.SolidsControlEquipment;
    let data = (!forceLoad) ? LS.getLocalStorageString(key) : null;
    if (data) {
      this.solidsData = this.toSolidsView(JSON.parse(data));
      this.solidsIdString = this.solidsData[0] ? this.solidsData[0].solidsControlEquipmentId : this.rigIdString;
      return "Success - from cache";
    }
    else {
      var url = Config.APIUrlCore + environment.solidsControlEquipment.getByRigId;
      return await this.api.get<Array<Models.SolidsControlEquipment>>(url.replace("{rigId}", rigId)).toPromise()
        .then(data => {
          data = data.map(obj => {
            // spread data over new instance to ensure all properties exist
            var def = new Models.SolidsControlEquipment();
            obj = { ...def, ...obj };
            // populate root properties from nested style objects
            switch (obj.style) {
              case "Centrifuge":
                obj.manufacturer = obj.centrifugeWITSML.manufacturer;
                obj.model = obj.centrifugeWITSML.model;
                obj.capFlow = obj.centrifugeWITSML.capFlow;
                obj.equipmentId = obj.centrifugeWITSML.centrifugeId;
                break;
              case "Degasser":
                obj.manufacturer = obj.degasserWITSML.manufacturer;
                obj.model = obj.degasserWITSML.model;
                obj.equipmentId = obj.degasserWITSML.degasserId;
                break;
              case "Desander":
                obj.manufacturer = obj.hydrocycloneWITSML.manufacturer;
                obj.model = obj.hydrocycloneWITSML.model;
                obj.equipmentId = obj.hydrocycloneWITSML.hydrocycloneId;
                break;
              case "Desilter":
                obj.manufacturer = obj.hydrocycloneWITSML.manufacturer;
                obj.model = obj.hydrocycloneWITSML.model;
                obj.equipmentId = obj.hydrocycloneWITSML.hydrocycloneId;
                break;
              case "Mud Cleaner":
              case "MudCleaner":
                obj.desanderNumCones = obj.desander.desanderNumCones;
                obj.desanderConeSize = obj.desander.desanderConeSize;
                obj.desilterNumCones = obj.desilter.desilterNumCones;
                obj.desilterConeSize = obj.desilter.desilterConeSize;
                obj.numberOfScreens = obj.shaker.numberOfScreens;
                obj.screenLayout = obj.shaker.screenLayout;
                obj.equipmentId = obj.mudCleanerId;
                obj.style = "MudCleaner";
                break;
              // case "OtherSCE":
              //   obj.manufacturer = obj.hydrocycloneWITSML.manufacturer;
              //   obj.model = obj.hydrocycloneWITSML.model;
              //   obj.equipmentId = obj.hydrocycloneWITSML.hydrocycloneId;
              //   break;
              case "Shaker":
                obj.manufacturer = obj.shakerWITSML.manufacturer;
                obj.model = obj.shakerWITSML.model;
                obj.equipmentId = obj.shakerWITSML.shakerId;
                break;
            }
            return obj;
          });
          data = this.sortSolidsControlEquipment(data);
          this.solidsData = this.toSolidsView(data);
          this.solidsIdString = this.solidsData[0] ? this.solidsData[0].solidsControlEquipmentId : this.rigIdString;
          return API_RESPONSE.SUCCESS;
        })
        .catch(err => {
          this.solidsData = [];
          return err;
        },
        );
    }
  }

  public loadClonedSolidsControlEquipment(data: Array<Models.SolidsControlEquipment>) {
    if (data) {
      for (let obj of data) {
        if (obj) {
          switch (obj.style) {
            case "Centrifuge":
              obj.manufacturer = obj.centrifugeWITSML.manufacturer;
              obj.model = obj.centrifugeWITSML.model;
              obj.capFlow = obj.centrifugeWITSML.capFlow;
              obj.equipmentId = obj.centrifugeWITSML.centrifugeId;
              break;
            case "Degasser":
              obj.manufacturer = obj.degasserWITSML.manufacturer;
              obj.model = obj.degasserWITSML.model;
              obj.equipmentId = obj.degasserWITSML.degasserId;
              break;
            case "Desander":
              obj.manufacturer = obj.hydrocycloneWITSML.manufacturer;
              obj.model = obj.hydrocycloneWITSML.model;
              obj.equipmentId = obj.hydrocycloneWITSML.hydrocycloneId;
              break;
            case "Desilter":
              obj.manufacturer = obj.hydrocycloneWITSML.manufacturer;
              obj.model = obj.hydrocycloneWITSML.model;
              obj.equipmentId = obj.hydrocycloneWITSML.hydrocycloneId;
              break;
            case "Mud Cleaner":
            case "MudCleaner":
              obj.desanderNumCones = obj.desander.desanderNumCones;
              obj.desanderConeSize = obj.desander.desanderConeSize;
              obj.desilterNumCones = obj.desilter.desilterNumCones;
              obj.desilterConeSize = obj.desilter.desilterConeSize;
              obj.numberOfScreens = obj.shaker.numberOfScreens;
              obj.screenLayout = obj.shaker.screenLayout;
              obj.equipmentId = obj.mudCleanerId;
              obj.style = "MudCleaner";
              break;
            case "Shaker":
              obj.manufacturer = obj.shakerWITSML.manufacturer;
              obj.model = obj.shakerWITSML.model;
              obj.equipmentId = obj.shakerWITSML.shakerId;
              break;
          }
        }
      }
      data = this.sortSolidsControlEquipment(data);
      this.solidsData = this.toSolidsView(data);
      this.solidsIdString = this.solidsData[0] ? this.solidsData[0].solidsControlEquipmentId : this.rigIdString;
    }
  }

  public async postClonedSolidsControlEquipment(obj: Models.SolidsControlEquipment) {
    var url = Config.APIUrlCore + environment.solidsControlEquipment.post;

    let solid: any = null;
    let common = new Models.SCE_common();
    common.rigId = obj.rigId;
    common.rigUtilizationId = obj.rigUtilizationId;
    common.solidsControlEquipmentId = obj.solidsControlEquipmentId;
    common.style = obj.style;
    common.partNumber = obj.partNumber;

    switch (obj.style) {
      case "Centrifuge":
        let eq1 = new Models.SCE_Centrifuge();
        eq1.centrifugeWITSML = obj.centrifugeWITSML;
        eq1.centrifugeWITSML.manufacturer = obj.centrifugeWITSML.manufacturer;
        eq1.centrifugeWITSML.model = obj.centrifugeWITSML.model;
        eq1.centrifugeWITSML.capFlow = obj.centrifugeWITSML.capFlow;
        eq1.nominalRPM = obj.nominalRPM;
        solid = { ...eq1, ...common };
        //delete obj.manufacturer;
        break;
      case "Mud Cleaner":
      case "MudCleaner":
        let eq2 = new Models.SCE_MudCleaner();
        eq2.mudCleanerId = obj.mudCleanerId;
        eq2.manufacturer = obj.manufacturer ? obj.manufacturer : "";
        eq2.model = obj.model ? obj.model : "?";
        eq2.desander.desanderConeSize.value = obj.desander.desanderConeSize.value;
        eq2.desander.desanderNumCones = obj.desander.desanderNumCones;
        eq2.desilter.desilterConeSize.value = obj.desilter.desilterConeSize.value;
        eq2.desilter.desilterNumCones = obj.desilter.desilterNumCones;
        eq2.shaker.numberOfScreens = obj.shaker.numberOfScreens;
        eq2.shaker.screenLayout = obj.shaker.screenLayout;
        solid = { ...eq2, ...common };
        solid.style = "Mud Cleaner";
        break;
      case "Degasser":
        let eq3 = new Models.SCE_Degasser();
        eq3.degasserWITSML = obj.degasserWITSML;
        eq3.degasserWITSML.manufacturer = obj.degasserWITSML.manufacturer;
        eq3.degasserWITSML.model = obj.degasserWITSML.model;
        eq3.class = obj.class ? obj.class : "";
        solid = { ...eq3, ...common };
        break;
      case "Desander":
        let eq4 = new Models.SCE_Desander();
        eq4.hydrocycloneWITSML = obj.hydrocycloneWITSML;
        eq4.hydrocycloneWITSML.manufacturer = obj.hydrocycloneWITSML.manufacturer;
        eq4.hydrocycloneWITSML.model = obj.hydrocycloneWITSML.model;
        eq4.desanderConeSize = obj.desanderConeSize;
        eq4.desanderNumCones = obj.desanderNumCones;
        solid = { ...eq4, ...common };
        break;
      case "Desilter":
        let eq5 = new Models.SCE_Desilter();
        eq5.hydrocycloneWITSML = obj.hydrocycloneWITSML;
        eq5.hydrocycloneWITSML.manufacturer = obj.hydrocycloneWITSML.manufacturer;
        eq5.hydrocycloneWITSML.model = obj.hydrocycloneWITSML.model;
        eq5.desilterConeSize = obj.desilterConeSize;
        eq5.desilterNumCones = obj.desilterNumCones;
        solid = { ...eq5, ...common };
        break;
      case "Shaker":
        let eq6 = new Models.SCE_Shaker();
        eq6.shakerWITSML = obj.shakerWITSML;
        eq6.shakerWITSML.manufacturer = obj.shakerWITSML.manufacturer;
        eq6.shakerWITSML.model = obj.shakerWITSML.model;
        eq6.numberOfScreens = obj.numberOfScreens;
        eq6.screenLayout = obj.screenLayout;
        solid = { ...eq6, ...common };
        break;
    }

    return await this.api.post<any>(url, solid).toPromise()
      .then(resp => {
        return API_RESPONSE.SUCCESS;
      })
      .catch(err => {
        return err;
      });
  }

  // create/update
  public async postSolidsControlEquipment(obj: Models.SolidsControlEquipment) {
    let solid: any = null;
    let common = new Models.SCE_common();
    obj.style = this.toSolidStyleData(obj.style);
    common.rigId = obj.rigId;
    common.rigUtilizationId = obj.rigUtilizationId;
    common.solidsControlEquipmentId = obj.solidsControlEquipmentId;
    if (obj?.copiedFrom) {
      common.copiedFrom = obj.copiedFrom;
    }
    common.style = obj.style;
    common.partNumber = obj.partNumber;
    switch (obj.style) {
      case "Centrifuge":
        let eq1 = new Models.SCE_Centrifuge();
        eq1.centrifugeWITSML = obj.centrifugeWITSML;
        eq1.centrifugeWITSML.manufacturer = obj.manufacturer;
        eq1.centrifugeWITSML.model = obj.model;
        eq1.centrifugeWITSML.capFlow = obj.capFlow;
        eq1.nominalRPM = obj.nominalRPM;
        solid = { ...eq1, ...common };
        //delete obj.manufacturer;
        break;
      case "Conditioner":
      case "MudCleaner":
        let eq2 = new Models.SCE_MudCleaner();
        eq2.mudCleanerId = obj.mudCleanerId;
        eq2.manufacturer = obj.manufacturer ? obj.manufacturer : "";
        eq2.model = obj.model ? obj.model : "?";
        eq2.desander.desanderConeSize.value = obj.desanderConeSize.value;
        eq2.desander.desanderNumCones = obj.desanderNumCones;
        eq2.desilter.desilterConeSize.value = obj.desilterConeSize.value;
        eq2.desilter.desilterNumCones = obj.desilterNumCones;
        eq2.shaker.numberOfScreens = obj.numberOfScreens;
        eq2.shaker.screenLayout = obj.screenLayout;
        solid = { ...eq2, ...common };
        solid.style = "Mud Cleaner";
        break;
      case "Degasser":
        let eq3 = new Models.SCE_Degasser();
        eq3.degasserWITSML = obj.degasserWITSML;
        eq3.degasserWITSML.manufacturer = obj.manufacturer;
        eq3.degasserWITSML.model = obj.model;
        eq3.class = obj.class ? obj.class : "";
        solid = { ...eq3, ...common };
        break;
      case "Desander":
        let eq4 = new Models.SCE_Desander();
        eq4.hydrocycloneWITSML = obj.hydrocycloneWITSML;
        eq4.hydrocycloneWITSML.manufacturer = obj.manufacturer;
        eq4.hydrocycloneWITSML.model = obj.model;
        eq4.desanderConeSize = obj.desanderConeSize;
        eq4.desanderNumCones = obj.desanderNumCones;
        solid = { ...eq4, ...common };
        break;
      case "Desilter":
        let eq5 = new Models.SCE_Desilter();
        eq5.hydrocycloneWITSML = obj.hydrocycloneWITSML;
        eq5.hydrocycloneWITSML.manufacturer = obj.manufacturer;
        eq5.hydrocycloneWITSML.model = obj.model;
        eq5.desilterConeSize = obj.desilterConeSize;
        eq5.desilterNumCones = obj.desilterNumCones;
        solid = { ...eq5, ...common };
        break;
      case "Shaker":
        let eq6 = new Models.SCE_Shaker();
        eq6.shakerWITSML = obj.shakerWITSML;
        eq6.shakerWITSML.manufacturer = obj.manufacturer;
        eq6.shakerWITSML.model = obj.model;
        eq6.numberOfScreens = obj.numberOfScreens;
        eq6.screenLayout = obj.screenLayout;
        solid = { ...eq6, ...common };
        break;
      // case "OtherSCE":
      //   break;
    }
    delete solid.equipmentId;

    var url = Config.APIUrlCore + environment.solidsControlEquipment.post;
    return await this.api.post<any>(url, solid).toPromise()
      .then(resp => {
        return API_RESPONSE.SUCCESS;
      })
      .catch(err => {
        return err;
      });
  }

  // delete
  public async deleteSolidsControlEquipment(obj: Models.SolidsControlEquipment) {
    var url = Config.APIUrlCore + environment.solidsControlEquipment.delete;
    obj.style = this.toSolidStyleData(obj.style);
    url = url.replace("{sceStyle}", obj.style);
    //url = url.replace("{sceTypeId}", obj.equipmentId);
    switch (obj.style) {
      case "Centrifuge":
        url = url.replace("{sceTypeId}", obj.centrifugeWITSML.centrifugeId);
        break;
      case "Degasser":
        url = url.replace("{sceTypeId}", obj.degasserWITSML.degasserId);
        break;
      case "Desander":
        url = url.replace("{sceTypeId}", obj.hydrocycloneWITSML.hydrocycloneId);
        break;
      case "Desilter":
        url = url.replace("{sceTypeId}", obj.hydrocycloneWITSML.hydrocycloneId);
        break;
      case "Mud Cleaner":
      case "MudCleaner":
        url = url.replace("{sceTypeId}", obj.mudCleanerId);
        break;
      case "Shaker":
        url = url.replace("{sceTypeId}", obj.shakerWITSML.shakerId);
        break;
    }
    return await this.api.post<Models.SolidsControlEquipment>(url, obj).toPromise()
      .then(resp => {
        return API_RESPONSE.SUCCESS;
      })
      .catch(err => {
        return err;
      });
  }

  public searchRigs(params: { rigName?: string, contractor?: string } = {}): Observable<any> {
    if (!params.rigName) delete params.rigName;
    if (!params.contractor) delete params.contractor;
    return this.api.post<any>(Config.APIUrlCore + environment.rigs.searchByParams, params);
  }

  public getRigById(rigId: string) {
    return this.api.get<any>(Config.APIUrlCore + environment.rigPackets.getRigByRigId.replace("{rigId}", rigId));
  }

  public sortPumps(data: Array<Models.Pump>): Array<Models.Pump> {
    var sorted = data.sort((a, b) =>
      (a.mudPumpWITSML.typePump > b.mudPumpWITSML.typePump) ? 1 :
        (a.mudPumpWITSML.typePump === b.mudPumpWITSML.typePump) ? ((a.mudPumpWITSML.model > b.mudPumpWITSML.model) ? 1 : -1) : -1);
    return sorted;
  }

  public async loadPumps(rigId: string, forceLoad: boolean) {
    var key = LS.LOCAL_STORAGE_KEY.Data.Rig.Pumps;
    let data = (!forceLoad) ? LS.getLocalStorageString(key) : null;
    if (data) {
      this.pumpsDataStringInit = data;
      this.pumpsData = JSON.parse(data);
      return "Success - from cache";
    }
    else {
      var url = Config.APIUrlCore + environment.mudPump.getByRigId;
      return await this.api.get<Array<Models.Pump>>(url.replace("{rigId}", rigId)).toPromise()
        .then(data => {
          data = data.map(obj => {
            // spread returned data over new instance to ensure all properties
            var def = new Models.Pump();
            obj = { ...def, ...obj };
            return obj;
          });
          data = this.sortPumps(data);
          this.pumpsData = data;
          this.pumpsDataStringInit = JSON.stringify(data);
          LS.setLocalStorageString(key, this.pumpsDataStringInit)
          return API_RESPONSE.SUCCESS;
        })
        .catch(err => {
          this.pumpsData = [];
          this.pumpsDataStringInit = '[]';
          return err;
        },
        );
    }
  }

  // create/update
  public async postPump(obj: Models.Pump) {
    var url = Config.APIUrlCore + environment.mudPump.post;
    return await this.api.post<Models.Pump>(url, obj).toPromise()
      .then(resp => {
        this.pumpsDataStringInit = JSON.stringify(this.pumpsData);
        return API_RESPONSE.SUCCESS;
      })
      .catch(err => {
        return err;
      });
  }

  // delete
  public async deletePump(obj: Models.Pump) {
    return this.deletePumpId(obj.mudPumpWITSML.mudPumpId);
  }

  public async deletePumpId(id: string) {
    var url = Config.APIUrlCore + environment.mudPump.delete;
    return await this.api.post<Models.Pump>(url.replace("{Id}", id), null).toPromise()
      .then(resp => {
        return API_RESPONSE.SUCCESS;
      })
      .catch(err => {
        return err;
      });
  }

  // maps the values of form into object
  mapForm2Rig(): Models.Rig {
    let form = this.rigProfileForm;
    let fg = form.controls.rig['controls'];
    let obj = this.rigData;
    obj.rigWITSML.title = fg.title.value;
    obj.rigWITSML.owner = fg.owner.value;
    obj.rigUtilization.rigUtilizationWITSML.airGap.value = fg.airGap.value;
    return obj;
  }

  mapForm2RigDetails(): Models.Rig {
    let form = this.rigProfileForm;
    let fg = form.controls.rigDetails['controls'];
    let obj = this.rigData;
    obj.rigWITSML.title = fg.rigName.value;
    obj.rigWITSML.owner = fg.contractor.value;
    obj.rigUtilization.rigUtilizationWITSML.airGap.value = fg.airGap.value;
    return obj;
  }

  mapForm2ServiceLine(): Models.ServiceLine {
    let form = this.rigProfileForm;
    let fg = form.controls.serviceLine['controls'];
    let obj = this.serviceData;
    obj.bopWITSML.idBoosterLine.value = fg.idBoosterLine.value;
    obj.bopWITSML.lenBoosterLine.value = fg.lenBoosterLine.value;
    obj.bopWITSML.idChkLine.value = fg.idChkLine.value;
    obj.bopWITSML.lenChkLine.value = fg.lenChkLine.value;
    obj.bopWITSML.idKillLine.value = fg.idKillLine.value;
    obj.bopWITSML.lenKillLine.value = fg.lenKillLine.value;
    return obj;
  }

  mapForm2SurfaceEquipment(): Models.SurfaceEquipment {
    let form = this.rigProfileForm;
    let fg = form.controls.surfaceEquipment['controls'];
    let obj = this.surfaceData;
    obj.surfaceEquipmentWITSML.idStandpipe.value = fg.idStandpipe.value;
    obj.surfaceEquipmentWITSML.lenStandpipe.value = fg.lenStandpipe.value;
    obj.surfaceEquipmentWITSML.idHose.value = fg.idHose.value;
    obj.surfaceEquipmentWITSML.lenHose.value = fg.lenHose.value;
    obj.surfaceEquipmentWITSML.idSwivel.value = fg.idSwivel.value;
    obj.surfaceEquipmentWITSML.lenSwivel.value = fg.lenSwivel.value;
    obj.surfaceEquipmentWITSML.idKelly.value = fg.idKelly.value;
    obj.surfaceEquipmentWITSML.lenKelly.value = fg.lenKelly.value;

    obj.surfaceEquipmentWITSML.useHose = this.convertToBoolean(obj.surfaceEquipmentWITSML.useHose)
    obj.surfaceEquipmentWITSML.useKelly = this.convertToBoolean(obj.surfaceEquipmentWITSML.useKelly)
    obj.surfaceEquipmentWITSML.useStandpipe = this.convertToBoolean(obj.surfaceEquipmentWITSML.useStandpipe)
    obj.surfaceEquipmentWITSML.useSwivel = this.convertToBoolean(obj.surfaceEquipmentWITSML.useSwivel)

    return obj;
  }

  mapForm2MudLiftPump(): Models.MudLiftPump {
    let form = this.rigProfileForm;
    let fg = form.controls.mudLiftPump['controls'];
    let obj = this.mudliftData;
    obj.mlpDepth.value = fg.mlpDepth.value;
    obj.id.value = fg.returnLineID.value;
    obj.od.value = fg.returnLineOD.value;
    obj.len.value = fg.returnLineLength.value;
    return obj;
  }

  // converts from API to other unit system
  convertCatalogSurfaceEquipment(data: Array<any>, columns: any) {
    var colSettingId = <unknown>this._utilities.getColumn(ColumnSettingsInfo.surfaceEquipment, 'surfaceEquipment_id');
    var columnId = <FieldLevelUoMSettings>colSettingId;
    var colSettingLen = <unknown>this._utilities.getColumn(ColumnSettingsInfo.surfaceEquipment, 'surfaceEquipment_length');
    var columnLen = <FieldLevelUoMSettings>colSettingLen;
    data.forEach((item) => {
      var obj = item.surfaceEquipmentWITSML;
      obj.idStandPipe.value = this._utilities.convertValue(obj.idStandPipe.value, columnId);
      obj.lenStandPipe.value = this._utilities.convertValue(obj.lenStandPipe.value, columnLen);
      obj.idHose.value = this._utilities.convertValue(obj.idHose.value, columnId);
      obj.lenHose.value = this._utilities.convertValue(obj.lenHose.value, columnLen);
      obj.idSwivel.value = this._utilities.convertValue(obj.idSwivel.value, columnId);
      obj.lenSwivel.value = this._utilities.convertValue(obj.lenSwivel.value, columnLen);
      obj.idKelly.value = this._utilities.convertValue(obj.idKelly.value, columnId);
      obj.lenKelly.value = this._utilities.convertValue(obj.lenKelly.value, columnLen);
      obj.idStandPipe.uom = columnId.currentUnit;
      obj.lenStandPipe.uom = columnLen.currentUnit;
      obj.idHose.uom = columnId.currentUnit;
      obj.lenHose.uom = columnLen.currentUnit;
      obj.idSwivel.uom = columnId.currentUnit;
      obj.lenSwivel.uom = columnLen.currentUnit;
      obj.idKelly.uom = columnId.currentUnit;
      obj.lenKelly.uom = columnLen.currentUnit;
    });
  }

  // converts from API to other unit system, no precision rounding
  convertCatalogPumps(data: Array<any>, columns: any) {
    var colSetting1 = this._utilities.getColumn(ColumnSettingsInfo.mudPumps, 'lenStroke');
    var colSetting2 = this._utilities.getColumn(ColumnSettingsInfo.mudPumps, 'odRod');
    var colSetting3 = this._utilities.getColumn(ColumnSettingsInfo.mudPumps, 'spmMx');
    data.forEach((obj) => {
      obj.lenStroke.value && (obj.lenStroke.value = this._utilities.convertValue(obj.lenStroke.value, colSetting1));
      obj.lenStroke.uom = colSetting1.currentUnit;

      obj.odRod.value && (obj.odRod.value = this._utilities.convertValue(obj.odRod.value, colSetting2));
      obj.odRod.uom = colSetting2.currentUnit;

      obj.spmMx.value && (obj.spmMx.value = this._utilities.convertValue(obj.spmMx.value, colSetting3));
      obj.spmMx.uom = colSetting3.currentUnit;
    });
  }

  // converts from API to other unit system
  convertCatalogSolids(data: Array<any>, columns: any) {
    let cacheValues: object = {};
    //let value = cacheValues[1];
    var colSetting1 = this._utilities.getColumn(ColumnSettingsInfo.solidsControlEquipment, 'desilterConeSize');
    var colSetting2 = this._utilities.getColumn(ColumnSettingsInfo.solidsControlEquipment, 'desanderConeSize');
    var colSetting3 = this._utilities.getColumn(ColumnSettingsInfo.solidsControlEquipment, 'nominalRPM');
    var colSetting4 = this._utilities.getColumn(ColumnSettingsInfo.solidsControlEquipment, 'capFlow');
    data.forEach((obj) => {
      obj.desilterConeSize.value && (obj.desilterConeSize.value = this._utilities.convertValue(obj.desilterConeSize.value, colSetting1));
      obj.desilterConeSize.uom = colSetting1.currentUnit;

      obj.desanderConeSize.value && (obj.desanderConeSize.value = this._utilities.convertValue(obj.desanderConeSize.value, colSetting2));
      obj.desanderConeSize.uom = colSetting2.currentUnit;

      obj.nominalRPM.value && (obj.nominalRPM.value = this._utilities.convertValue(obj.nominalRPM.value, colSetting3));
      obj.nominalRPM.uom = colSetting3.currentUnit;

      obj.capFlow.value && (obj.capFlow.value = this._utilities.convertValue(obj.capFlow.value, colSetting4));
      obj.capFlow.uom = colSetting4.currentUnit;
    });
  }

  // converts from API to other unit system
  convertPit(obj: Models.Pit, columns: any): Models.Pit {
    var converted = Object.assign({}, obj);
    converted.length.value = this._utilities.convertValue(obj.length.value, columns.find(x => x.colName == "length"));
    converted.width.value = this._utilities.convertValue(obj.width.value, columns.find(x => x.colName == "width"));
    converted.height.value = this._utilities.convertValue(obj.height.value, columns.find(x => x.colName == "height"));
    converted.secondaryHeight.value = this._utilities.convertValue(obj.secondaryHeight.value, columns.find(x => x.colName == "secondaryHeight"));
    converted.diameter.value = this._utilities.convertValue(obj.diameter.value, columns.find(x => x.colName == "diameter"));
    converted.secondaryDiameter.value = this._utilities.convertValue(obj.secondaryDiameter.value, columns.find(x => x.colName == "secondaryDiameter"));
    converted.pitWITSML.capMx.value = this._utilities.convertValue(obj.pitWITSML.capMx.value, columns.find(x => x.colName == "volume"));
    converted.depth.value = this._utilities.convertValue(obj.depth.value, columns.find(x => x.colName == "depth"));
    return converted;
  }

  // converts from other unit system to API
  revertPit(obj: Models.Pit, columns: any): Models.Pit {
    var reverted = Object.assign({}, obj);
    reverted.length.value = Number(this._utilities.revertValue(obj.length.value, columns.find(x => x.colName == "length")));
    reverted.width.value = Number(this._utilities.revertValue(obj.width.value, columns.find(x => x.colName == "width")));
    reverted.height.value = Number(this._utilities.revertValue(obj.height.value, columns.find(x => x.colName == "height")));
    reverted.secondaryHeight.value = Number(this._utilities.revertValue(obj.secondaryHeight.value, columns.find(x => x.colName == "secondaryHeight")));
    reverted.diameter.value = Number(this._utilities.revertValue(obj.diameter.value, columns.find(x => x.colName == "diameter")));
    reverted.secondaryDiameter.value = Number(this._utilities.revertValue(obj.secondaryDiameter.value, columns.find(x => x.colName == "secondaryDiameter")));
    reverted.pitWITSML.capMx.value = Number(this._utilities.revertValue(obj.pitWITSML.capMx.value, columns.find(x => x.colName == "volume")));
    reverted.depth.value = Number(this._utilities.revertValue(obj.depth.value, columns.find(x => x.colName == "depth")));
    return reverted;
  }

  convertToBoolean(value: string | boolean): boolean {
    if (typeof value == 'boolean') {
      return value
    }
    return value == 'true' ? true : false
  }

}
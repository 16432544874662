import { Injectable } from '@angular/core';
import { SettingsService } from './core/services/settings.service';
import { UnitSystemsService } from './core/services/unitsystems.service';
import 'rxjs/add/operator/toPromise';
import { initProgress, myconsole } from './utils/utils';
import { UtilitiesService } from './core/services/utilitiesservice.service';

@Injectable({
  providedIn: 'root'
})

export class AppLoadService {

  constructor(
    public _settings: SettingsService,
    public _unitSystems: UnitSystemsService,
    public _utilities: UtilitiesService
  ) {
  }

  // resolve required initial loading for app
  loadApp(): Promise<void> {
    myconsole("$timerStart", "loadApp");
    this._settings._utilities.setOnlineStatus();
    return new Promise<void>((resolve, reject) => {
      //var bootstrapError = environment.bootstrapError;
      //var cfg = this._settings._auth.getCurrentConfiguration();
      let promises = [];
      promises.push(this._unitSystems.loadUnitsystemQuantityUnits(false));
      promises.push(this._settings.loadTimeZones(false));
      promises.push(this._settings.getUserSettings(true));
      initProgress("Loading User Settings...");
      Promise.all(promises)
        .then(data => {
          this._utilities.profileSetting = data[2];
          if (this._settings.settings && this._settings.timeZones && this._unitSystems.catQuantityUnits) {
            this._unitSystems.setGlobalUnitSystem(this._settings.settings.activeUnitSystemId);
            this._settings._utilities.isAppInitialized$.next(true);
          }
        })
        .catch(err => {
          myconsole('loadApp error: ', err);
          initProgress(err);
        })
        .finally (() => {
          myconsole("$timerEnd", "loadApp");
          resolve();
        });
    });
  }

}

import { Injectable } from "@angular/core";
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import * as OB from "src/app/utils/objecter";
import { isNull } from "src/app/utils/utils";
import { percent, temperature } from "units-converter";
import { Range } from "../models/common.model";
import { JobType } from "../../utils/constants";

export class ValidatorsEx {

  public static prioritize: Array<string> = [
    "required",
    "requiredText",
    "mustBeAlphaNumeric",
    "mustBeAlphaNumericAnd",
    "mustBeNumber",
    "negative",
    "mustBeInteger",
    "nameIsExists",
    "maxLength",
    "max",
    "maxValue",
    "percentValue",
    "duplicate",
    "duplicateWithin",
    "equalToValue",
    "greaterThanZero",
    "greaterThanValue",
    "greaterThanMin",
    "greaterOrEqual",
    "lessThanOrEqual",
    "greater",
    "maxPercent",
    "overlap",
    "notChained",
    "limit",
    "containChild",
    "mustBeInList",
    "apiError"
  ];

  static isAlphaNumeric(str: string) {
    if(str && str.trim().length > 0) {
      const regEx: RegExp = /^[a-z0-9 .,]+$/i;
      return regEx.test(str);
    }
    return true;
  }

  static mustBeAlphaNumeric(): ValidatorFn {

    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!this.isAlphaNumeric(value)) {
        return { 'mustBeAlphaNumeric': true }
      }

      return null;
    }
  }

  static mustBeAlphaNumericAnd(specials: Array<string>): ValidatorFn {

    return (control: AbstractControl): ValidationErrors | null => {
      let value = control.value;

      if(value.length > 0) {
        let specStr = "";
        for(const charater of specials) 
        {
          if(charater == "undersore") {
            value = value.replace('_', "");
            specStr += "_" + " ";
          } else {
            value = value.replace(charater, "");
            specStr += charater + " ";
          }
        }
  
        if (!this.isAlphaNumeric(value)) {
          return { 'mustBeAlphaNumericAnd': {value: specStr} }
        }
      }

      return null;
    }
  }

  static limitDate(min?: Date, max?: Date): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      
      if(this.isValidDateRange(value, min, max) == false) {
        return { 'limitDate': true }
      }
      return null;
    }
  } 

    static greater(fromControlName: string, toControlName: string): ValidatorFn {
        return (formGroup: FormGroup): ValidatorFn => {
            const fromControl = formGroup.controls[fromControlName];
            const toControl = formGroup.controls[toControlName];

            if (fromControl && toControl) {
                this.resetErrors(fromControl, "greater");
                this.resetErrors(toControl, "greater");
                if(toControl.errors) {
                  return;
                }

                let fromVal = isNull(fromControl.value) ? null : fromControl.value;
                let toVal = isNull(toControl.value) ? null : toControl.value;
                if (fromVal !== null && toVal !== null && fromVal >= toVal) {
                    toControl.setErrors({ greater: { controlName: fromControlName.toLowerCase() } });
                } else {
                    toControl.setErrors(null);
                }
            }
        }
    }

    static lessThanOrEqual(fromControlName: string, toControlName: string): ValidatorFn {
      return (formGroup: FormGroup): ValidatorFn => {
        const fromControl = formGroup.controls[fromControlName];
        const toControl = formGroup.controls[toControlName];

        if (fromControl && toControl) {
            this.resetErrors(fromControl, "lessThanOrEqual");
            this.resetErrors(toControl, "lessThanOrEqual");
            if(fromControl.errors) {
              return;
            }

            let fromVal = isNull(fromControl.value) ? null : fromControl.value;
            let toVal = isNull(toControl.value) ? null : toControl.value;
            if (fromVal !== null && toVal !== null && fromVal > toVal) {
              fromControl.setErrors({ lessThanOrEqual: { controlName: toControlName.toLowerCase() } });
            } else {
              fromControl.setErrors(null);
            }
        }
      }
    }

    static greaterOrEqual(fromControlName: string, toControlName: string): ValidatorFn {
      return (formGroup: FormGroup): ValidatorFn => {
        const fromControl = formGroup.controls[fromControlName];
        const toControl = formGroup.controls[toControlName];
        if (fromControl && toControl) {
          this.resetErrors(fromControl, "greaterOrEqual");
          this.resetErrors(toControl, "greaterOrEqual");

          if(toControl.errors) return;

          let fromVal = isNull(fromControl.value) ? null : fromControl.value;
          let toVal = isNull(toControl.value) ? null : toControl.value;
          if (fromVal !== null && toVal !== null && fromVal > toVal) {
            toControl.setErrors({ greaterOrEqual: { controlName: fromControlName.toLowerCase() } });
          } else {
            toControl.setErrors(null);
          }
        }
      }
    }  

    static negative(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {

            const currentValue: number = +control.value;

            if (currentValue && currentValue < 0) {
                return { 'negative': true }
            }

            return null;
        }
    }

    static maxLength(max: number): ValidatorFn {
      return (control: AbstractControl): ValidationErrors | null => {

          const currentValue = control.value;

          if (currentValue.length > max) {
              return { 'maxLength': {value: max}  }
          }

          return null;
      }
    }

    static requiredText(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
          let value: string = control.value;
          if (value && value.trim().length > 0) {
            return null;
          }
          return { 'required': true };
        }
      }

    static mustBeInList(list: any[]): ValidatorFn {
      return (control: AbstractControl): ValidationErrors | null => {
          if (control?.errors?.required) return;
          return !list?.includes(control.value) ? { mustBeInList: true } : null;
      }
    }

    static greaterThanZero(): ValidatorFn {

      return (control: AbstractControl): ValidationErrors | null => {
  
        if (control.value != null) {
          const currentValue: number = +control.value;
  
          if (currentValue <= 0) {
            return { 'greaterThanZero': true }
          }
  
          return null;
        }
        return null
      }
    }

    static maxValue(value: number): ValidatorFn {

      return (control: AbstractControl): ValidationErrors | null => {
  
        if (control.value != null) {
          const currentValue: number = +control.value;
  
          if (currentValue > value) {
            return { maxValue: {value: value} }
          }
  
          return null;
        }
        return null
      }
    }

    static requiredInteger(): ValidatorFn {
      return (control: AbstractControl): ValidationErrors | null => {
      if (control.value === null) return null;
        let value: number = control.value;
        if (!Number.isInteger(value)) {
          return { 'mustBeInteger': true }
        }
        return null;
      }
    }

    static requiredPercent(currentUnit): ValidatorFn {

      return (control: AbstractControl): ValidationErrors | null => {
        if (control.value != null) {
          const currentValue: number = +control.value;
          let max = percent(100).from('%').to(currentUnit);
          if (currentValue && (currentValue < 0 || currentValue > max.value)) {
            return { 'percentValue': true, maxValue: max.value }
          }
  
          return null;
        }
      }
    }

    static greaterThanTemperature(temp: number, currentUnit: string): ValidatorFn {

      return (control: AbstractControl): ValidationErrors | null => {
  
        if (control.value != null) {
          const currentValue: number = +control.value;
          let min = temperature(temp).from('°F').to(currentUnit);
          if (currentValue <= min.value) {
            return { 'greaterThanValue': true, value: min.value }
          }
  
          return null;
        }
      }
    }

    static maxPercent(maxPercent: number): ValidatorFn {

      return (control: AbstractControl): ValidationErrors | null => {
  
        const currentPercent: number = +control.value;
  
        if (!maxPercent) {
          return null
        }
  
        if (currentPercent > maxPercent) {
          return { 'maxPercent': true }
        }
  
        return null;
      }
    }

    static mustBeNumber(): ValidatorFn {

      return (control: AbstractControl): ValidationErrors | null => {
  
        if (isNaN(control.value)) {
          return { 'mustBeNumber': true }
        }
  
        return null;
      }
    }

    static notDuplicate(component, callbackFn: string): ValidatorFn {
      return (control: AbstractControl): ValidationErrors | null => {
        const controlName = this.getControlName(control);
        if(controlName) {
          let table = component[callbackFn];
          if(table && OB.isNull(control.value) == false) {
            for(let row of table) {
              if(row[controlName] == control.value){
                return { duplicate: {controlName: controlName.toLowerCase()}}
              }
            }
          }
        }
        return null;
      }
    }

    static notDuplicateArray(component, callbackFn: string): ValidatorFn {
      return (control: AbstractControl): ValidationErrors | null => {
        const controlName = this.getControlName(control);
        if(controlName) {
          let table = component[callbackFn];
          let counter = 0;
          if(table && OB.isNull(control.value) == false) {
            for(let row of table) {
              if(row[controlName] == control.value){
                counter++;
              }
            }
            if(counter > 1) {
              return { duplicate: {controlName: controlName.toLowerCase()}}
            }
          }
        }
        return null;
      }
    }

    static notDuplicateWithin(component, controlName, callbackFn: string, nameFn: string): ValidatorFn {
      return (formGroup: FormGroup): ValidatorFn => {
        const control = formGroup.controls[controlName];
        const val = control.value;
        const table = component[callbackFn];
        const name  = component[nameFn] ? component[nameFn]: nameFn;
        
        this.resetErrors(control, "duplicateWithin");
        if(control.errors) {
          return;
        }

        if(table) {
          for(let row of table) {
            if(row[controlName].toLowerCase() == control.value.toLowerCase()){
              control.setErrors({duplicateWithin: {value: name}});
            }
          }
        }
        
        return;
      }
    }

    static notOverlap(component, topControlName: string, endControlName: string, callbackFn: string, overlapField: string): ValidatorFn {
      return (formGroup: FormGroup): ValidatorFn => {

        const topControl = formGroup.controls[topControlName];
        const endControl = formGroup.controls[endControlName];
        const topVal = topControl.value;
        const endVal = endControl.value;
        const table = component[callbackFn];
        const overlapWith = component[overlapField] ? component[overlapField]: overlapField;
        
        this.resetErrors(topControl, "overlap");
        this.resetErrors(endControl, "overlap");
        if(topControl.errors || endControl.errors) {
          return;
        }

        let hasOverlap = this.isOverlap(table, topVal, endVal, topControlName, endControlName);
        if(hasOverlap) {
          topControl.setErrors({overlap: {overlapWith: overlapWith}});
          endControl.setErrors({overlap: {overlapWith: overlapWith}});
        } else {
          topControl.setErrors(null);
          endControl.setErrors(null);
        }
        return;
      }
    }

  static chained(component, topControlName: string, endControlName: string, callbackFn: string): ValidatorFn {
    return (formGroup: FormGroup): ValidatorFn => {
      const table = component[callbackFn];
      if (table.length < 1) {
        return;
      }
      const topControl = formGroup.controls[topControlName];
      const endControl = formGroup.controls[endControlName];
      this.resetErrors(topControl, "notChained");
      this.resetErrors(endControl, "notChained");
      if (topControl.errors || endControl.errors) {
        return;
      }
      const topVal = topControl.value;
      const endVal = endControl.value;
      table.push({
        [topControlName]: topVal,
        [endControlName]: endVal
      })

      let isChained = this.isChained(table, topControlName, endControlName);
      if (!isChained) {
        topControl.setErrors({ notChained: {topControlName: topControlName, endControlName: endControlName} });
        endControl.setErrors({ notChained: {topControlName: topControlName, endControlName: endControlName} });
      } else {
        topControl.setErrors(null);
        endControl.setErrors(null);
      }
      return;
    }
  }
    static limit(component, topControlName: string, endControlName: string, callbackFn: string): ValidatorFn {
      return (formGroup: FormGroup): ValidatorFn => {
        const topControl = formGroup.controls[topControlName];
        const endControl = formGroup.controls[endControlName];
        const topVal = topControl.value;
        const endVal = endControl.value;
        const ranger = component[callbackFn] as Range;
        
        this.resetErrors(topControl, "limit");
        this.resetErrors(endControl, "limit");
        if(topControl.errors || endControl.errors || OB.isNull(ranger)) {
          return;
        }

        if(topVal < ranger.start || endVal > ranger.end) {
          topControl.setErrors({limit: {startValue: ranger.start, endValue: ranger.end}});
          endControl.setErrors({limit: {startValue: ranger.start, endValue: ranger.end}});
        } else {
          topControl.setErrors(null);
          endControl.setErrors(null);
        }
        return;
      }
    }


    static containTargetSet(topControlName: string, endControlName: string, intervalFn: () => Range, targetSetFn: () => Array<Range>): ValidatorFn {
      return (formGroup: FormGroup): ValidatorFn => {
        const topControl = formGroup.controls[topControlName];
        const endControl = formGroup.controls[endControlName];
        const topVal = topControl.value;
        const endVal = endControl.value;
        const targetSets = targetSetFn();
        const interval = intervalFn();
        
        this.resetErrors(topControl, "containChild");
        this.resetErrors(endControl, "containChild");
        if(topControl.errors || endControl.errors || OB.isNull(targetSets) || OB.isNull(interval)) {
          return;
        }

        for(const targetSet of targetSets) {
          if(targetSet.start == interval.start && topVal >= targetSet.end) {
            topControl.setErrors({containChild: {controlName: topControlName.toLowerCase(), childName: "Target Sets"}});
            return;
          } else if(targetSet.start != interval.start && topVal > targetSet.start) {
            topControl.setErrors({containChild: {controlName: topControlName.toLowerCase(), childName: "Target Sets"}});
            return;
          }
  
          if(targetSet.end == interval.end && endVal <= targetSet.start) {
            endControl.setErrors({containChild: {controlName: endControlName.toLowerCase(), childName: "Target Sets"}});
            return;
          } else if(targetSet.end != interval.end && endVal < targetSet.end) {
            endControl.setErrors({containChild: {controlName: endControlName.toLowerCase(), childName: "Target Sets"}});
            return;
          }
        }

        return;
      }
    }

    public static removeError(control, validator) {
      let errors = control.errors;
      if(OB.isNull(errors)) return;
      const keys = Object.keys(errors);
      for(const key of keys) {
        if(key == validator) {
          OB.removeObj(errors, key);
          break;
        }
      }
      if(Object.keys(errors).length == 0) {
        errors = null;
      }
      control.setErrors(errors);
    }

    public static removeErrors(control, validators) {
      let errors = control.errors;
      if(OB.isNull(errors)) return;
      const keys = Object.keys(errors);
      for(const key of keys) {
        for(const validator of validators) {
          if(key == validator) {
            OB.removeObj(errors, key);
          }
        }
      }
      if(Object.keys(errors).length == 0) {
        errors = null;
      }
      control.setErrors(errors);
    }

    public static resetErrors(control, validator) {
      let errors = control.errors;
      if(OB.isNull(errors)) return;
      const keys = Object.keys(errors);
      let currentValidatorIndex = this.prioritize.findIndex(x=>x == validator);
      for(const key of keys) {
        const nextValidatorIndex = this.prioritize.findIndex(x=>x == key);
        if(currentValidatorIndex <= nextValidatorIndex) {
          OB.removeObj(errors,key);
        }
      }
      if(Object.keys(errors).length == 0) {
        errors = null;
      }
      control.setErrors(errors);
    }

    public static isOverlap(table, topVal, endVal, topControlName, endControlName) {
      for (const row of table) {
        const rowTopValue = OB.getObj(row, topControlName);
        const rowEndValue = OB.getObj(row, endControlName);
        
        if (topVal >= rowTopValue && endVal <= rowEndValue) {
          return true;
        }

        if (topVal < rowTopValue && endVal > rowTopValue && endVal <= rowEndValue) {
          return true;
        }

        if (topVal >= rowTopValue && topVal < rowEndValue && endVal > rowEndValue) {
          return true;
        }

        if (topVal < rowTopValue && endVal > rowEndValue) {
          return true;
        }

      }

      return false;
    }

    public static isChained(table, topControlName = "topMd", endControlName = "endMd") {
      if (table && table.length > 1) {
        OB.sortArr(table, endControlName);
        let prevRow = null;
        for (const row of table) {
          if (prevRow != null) {
            const prevEnd = OB.getObj(prevRow, endControlName);
            const rowTop = OB.getObj(row, topControlName);
            
            if (!prevEnd || !rowTop || prevEnd !== rowTop) {
              return false;
            }
          }
          prevRow = row;
        }
      }
      return true;
    }

    private static getControlName(control: AbstractControl) {
      if(control.parent) {
        const formGroup = control.parent.controls;
        return Object.keys(formGroup).find(name => control === formGroup[name]) || null;
      }
      return null;
    }

    static notLessThanMin(values: number | any, validationOption?: any): ValidatorFn {

      return (control: AbstractControl): ValidationErrors | null => {
        if (typeof values === 'number') {
          const maxValue: number = +control.value;
          if (maxValue >= values || (!validationOption.appliedZero && (maxValue == 0 || values == 0))) {
            return null
          }
  
          return { 'notLessThanMin': true }
        }
        else {
          const maxValue: number = +values.maxValue
          const minValue: number = +values.minValue
  
          if (maxValue >= minValue || (!validationOption.appliedZero && (maxValue == 0 || minValue == 0))) {
            return null
          }
  
          return { 'notLessThanMin': true }
        }
      }
    }

    static isValidDateRange(date: string, min?: Date, max?: Date) {
      const minDate = min? min: new Date("1753-01-02");
      const maxDate = max? max: new Date("9999-12-30");
  
      if (date) {
        const inputDate = new Date(date);
        if(inputDate < minDate || inputDate > maxDate) {
          return false
        }
      }
      return true;
    }
}

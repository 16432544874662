<div id="unit-system-footer" *ngIf="_utilities.showDashboard$ | async " class="results-unitsystem default-gray">
  <div class="medium-nav">{{defaultSystemLabel}} {{unitsystem}} {{referedUnit}}</div>
  <div class="status-system" *ngIf="isLocal">
    <div *ngIf="isOnNetwork" class="status-content">
      <div class="status-icon icon-online"></div>
      <span class="status-text">{{'statusSystem.onnetwork' |translate}}</span>
    </div>
    <div *ngIf="!isOnNetwork" class="status-content">
      <div class="status-icon icon-offline"></div>
      <span class="status-text">{{'statusSystem.offnetwork' |translate}}</span>
    </div>
    <div class="p-1">&nbsp;</div>
    <div *ngIf="isOnInternet" class="status-content">
      <div class="status-icon icon-online"></div>
      <span class="status-text">{{'statusSystem.online' |translate}}</span>
    </div>
    <div *ngIf="!isOnInternet" class="status-content">
      <div class="status-icon icon-offline"></div>
      <span class="status-text">{{'statusSystem.offline' |translate}}</span>
    </div>
  </div>
</div>
<ngx-smart-modal
  #selectPopup
  [identifier]="MODAL_IDENTIFIERS.SELECT_POPUP"
  [dismissable]="false"
  [closable]="false"
  (onOpen)="onOpenModal()"
  customClass="modal-content modal-custom no-padding confirmNewTabData confirm-modal-container">
    <ng-container>
        <div class="confirmationOnNewTab confirm-modal-inner">
            <div class="modal-header">
                <h1 class="header">{{dialogData?.header | translate}}</h1>
                <button type="button" class="close" aria-label="Close" (click)="closeModal()">
                    <img class="closeIcon" />
                </button>
            </div>
            <div class="modal-body">
                <select class="select-dropdown" [(ngModel)]="selected">
                    <option class="option-dropdown" *ngFor="let option of dialogData?.options" value="{{option?.value || option}}">
                        {{option?.label || option}}
                    </option>
                </select>
            </div>
            <div class="modal-footer">
                <button class="bttn bttn-secondary mx-1" (click)="closeModal()">{{'buttons.cancel'|translate}}</button>
                <button class="bttn bttn-primary mx-1" (click)="onSelectOption()">{{'buttons.select'|translate}}</button>
            </div>
        </div>
    </ng-container>
</ngx-smart-modal>
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import { MessageService } from '../../shared/message.service';
import { apiParam } from '../models/apiParam.model';

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  public BasicAuthHeaders;
  public JWTAuthHeaders;
  public testOffline: boolean = false;
  public testOffVpn: boolean = false;

  constructor(
    public http: HttpClient,
    public messageService: MessageService
  ) {
    let token = btoa('Secret:Squirrel01@');

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
    headers = headers.append('Cache-Control', 'private, max-age=120');
    headers = headers.append('X-Content-Type-Options', 'nosniff');
    headers = headers.append('Content-Security-Policy', "default-src 'none';");
    headers = headers.append('Content-Security-Policy', "script-src 'self';");
    headers = headers.append('Content-Security-Policy', "connect-src 'self';");
    headers = headers.append('Content-Security-Policy', "img-src 'self';");
    headers = headers.append('Content-Security-Policy', "style-src 'self';");
    headers = headers.append('Content-Security-Policy', "frame-ancestors 'self';");
    headers = headers.append('Content-Security-Policy', "form-action 'self';");
    headers = headers.append('Strict-Transport-Security', 'max-age=86400');
    this.JWTAuthHeaders = headers;
  
    let headersAuth = headers.append('skipIntercept', 'true');
    headersAuth = headersAuth.append('Authorization', 'Bearer ' + token);
    this.BasicAuthHeaders = headersAuth;
  }

  public get<T>(url: string, id?: string, params?: apiParam[]): Observable<T> {

    if (id) {
      url = `${url}/${id}`;
    }

    if (params) {
      for (let param of params) {
        if (url) {
          url = url.replace(param.name, param.value);
        }
      }
    }
    console.log("api.get", url);
    let request = this.http.get<T>(url, this.HeaderJWTAuth()).catch(this.handleError);
    return request;
  }

  public post<T>(url: string, data: T): Observable<T> {
    console.log("api.post", url);
    let request = this.http.post<T>(url, data, this.HeaderJWTAuth()).catch(this.handleError);
    return request;
  }

  public put<T>(url: string, data: T): Observable<T> {
    console.log("api.put", url);
    let request = this.http.put<T>(url, data, this.HeaderJWTAuth()).catch(this.handleError);
    return request;
  }

  public patch<T>(url: string, data: T): Observable<T> {
    console.log("api.patch", url);
    let request = this.http.patch<T>(url, data, this.HeaderJWTAuth()).catch(this.handleError);
    return request;
  }

  public delete<T>(url: string, id?: string): Observable<T> {
    if (id) {
      url = `${url}/${id}`;
    }
    console.log("api.delete", url);
    let request = this.http.delete<T>(url, this.HeaderJWTAuth()).catch(this.handleError);
    return request;
  }

  // api service call for local token generator
  public localtoken<T>(url: string): Observable<T> {
    if (url.endsWith("username")) {
      return this.http.get<T>(url, this.HeaderBasicAuth()).catch(this.handleError);
    } else {
      const data = {
        upn: "local_user@halliburton.com",
        eMail: "local_user@halliburton.com"
      }
      return this.http.post<T>(url, data, this.HeaderBasicAuth()).catch(this.handleError);
    }
  }

  // Log a message with the MessageService
  public log(message: string) {
    this.messageService.add(`HttpService: ${message}`);
  }

  public Header() {
    let header = new HttpHeaders({ 'Content-Type': 'application/json' });
    return { headers: header };
  }

  public HeaderBasicAuth() {
    return { headers: this.BasicAuthHeaders };
  }

  public HeaderJWTAuth() {
    return { headers: this.JWTAuthHeaders };
  }

  // this object comes from error-interceptor
  public handleError(errorResponse: any) {
    return throwError(errorResponse);
  }
}

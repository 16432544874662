// This file is imported by other build environment files.

export const defaultEnvironment = {
  appSettingsFile: '/assets/appsettings/appSettings.json',
  build_date: "#{Build.BuildDate}#",
  build_number: "#{Build.BuildNumber}#",
  deploy: "",
  darkTheme: "dark",
  lightTheme: "light",
  newWindow: "newWindow",
  redirectUri: "",
  redirectUriFolder: "/resolveRoutes",
  redirectLogoutUri: "",
  redirectLogoutUriFolder: "/logout",
  consentScopes: ["user.read"],

  spinner: {
    type: 'ball-scale-multiple', // any from LoadAwesome, such as ball-clip-rotate
    size: "medium", // default, small, medium, large
    color: "white",
  },

  debug: {
    bootstrapError: '',
    console: true,
    localstorage: false,
    users: ['*'],
    indexElement: null,
    indexElementText: "",
  },

  web: {
    app_Insight_Key: "",
    production: false,
    isCloud: false,
    isOnInternet: false,
    isOnNetwork: false,
    profilepic: 'https://graph.microsoft.com/v1.0/me/photo/$value',
},

  auth: {
    guard: null,
    intercept: null,
  },

  api: {
    service: {
      catalog: "mscatalogs",
      core: "mscore",
      downhole: "msdownhole",
      hydraulics: "mshydraulics",
      packaging: "mspackaging",
      platform: "msplatform",
      rheology: "msrheology",
      survey: "mssurvey",
      volumes: "msvolume"
    },
    API_URL_Cloud: "",
    API_URL_Local: "https://localhost:",
    API_URL_Mock: 'http://localhost:4200/assets/data',
    API_PORTS_Cloud: {
      catalogs: "",
      core: "",
      downhole: "",
      hydraulics: "",
      packaging: "",
      platform: "",
      rheology: "",
      survey: "",
      volumes: ""
    },
    API_PORTS_Local: {
      catalogs: "5001",
      core: "5005",
      downhole: "5019",
      hydraulics: "5014",
      packaging: "5007",
      platform: "5035",
      rheology: "5010",
      survey: "5012",
      tokens: "5018",
      volumes: "5016",
    },
    Ocp_Apim_Subscription_Key: "",
    protectedResourceMap: [],
    token: "/api/Baroid/Tokens/v1",
    tokenUser: "/api/Baroid/Tokens/v1/username",
  },

  msal: {
    auth: {
      clientId: "clientid",
      clientSecret: "clientSecret",
      scope: "scope",
      authority: "https://login.microsoftonline.com/b7be7686-6f97-4db7-9081-a23cf09a96b5",
      redirectUri: "",
      postLogoutRedirectUri: "",
      navigateToLoginRequestUrl: true,
      validateAuthority: true
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: true
    },
    guard: {
      interactionType: "redirect",
      authRequest: {
        scopes: [
          "user.read"
        ]
      },
      loginFailedRoute: "/error"
    },
    interceptor: {
      interactionType: "redirect",
      protectedResourceMap: []
    },
  },

  register: {
    dashboard: "dashboard",
    job: "job",
  },

  catalogs: {
    //get: "/api/v1/catalogs",
    syncToLocal: '/api/Baroid/Local/DataSync/v1/syncCatalogs',
    updateVersion: '/api/Baroid/CatalogManagement/v1/updateVersion',
    latestVersion: '/api/Baroid/CatalogManagement/v1/latestVersion',
    checkForUpdates: '/api/Baroid/Local/DataSync/v1/checkForUpdates',
    quantityunits: '/api/Baroid/UnitSystemCatalog/v1/unitQuantities',
    componentTypes: '/api/v1/toolstrings/componenttypes',
    getAdditionalFluidPropertiesCatalog: '/api/Baroid/AdditionalFluidPropertiesCatalog/v1',
    lithologyType: "/api/Baroid/LithologyCatalog/v1/LithologyType",
    lithos: '/api/Baroid/LithostratigraphyCatalog/v1/lithostratigraphies',
    collectionInfo: "/api/Baroid/CatalogManagement/v1/catalogInformation",
    updateCollection: "/api/Baroid/CatalogManagement/v1/createUpdateDeleteData",
    createMany: "/api/Baroid/CatalogManagement/v1/createMany",
    collectionData: "/api/Baroid/CatalogManagement/v1/{collection}",
    //adding the fluid set dropdown api endpoints
    fluidCatalogs: {
      baseFluidTypes: "/api/Baroid/FluidCatalog/v1/baseFluidTypes",
      brines: "/api/Baroid/FluidCatalog/v1/brines",
      calculationBaseFluidTypes: "/api/Baroid/FluidCatalog/v1/calculationBaseFluidTypes",
      calculationBrines: "/api/Baroid/FluidCatalog/v1/calculationBrines",
      fluidCategories: "/api/Baroid/FluidCatalog/v1/fluidCategories",
      fluidSets: "/api/Baroid/FluidSetCatalog/v1",
      fluidSystems: '/api/Baroid/FluidCatalog/v1/fluidSystems',
      fluidLocations: '/api/Baroid/FluidCatalog/v1/fluidLocations',
    },
    jobs: {
      contractors: "/api/Baroid/PersonnelCatalog/v1/contractors?pageNumber=",
      operators: "/api/Baroid/PersonnelCatalog/v1/operators?pageNumber=",
      jobStatus: '/api/Baroid/JobCatalog/v1/JobStatus',
      jobTypes: '/api/Baroid/JobCatalog/v1/JobType',
      jobPurpose: '/api/Baroid/JobCatalog/v1/JobPurpose',
      jobCustomer: '/api/Baroid/JobCatalog/v1/JobCustomerType',
    },
    rigs: {
      pitStyles: "/api/Baroid/RigEquipmentCatalog/v1/pitStyles",
      pumps: '/api/Baroid/PumpCatalog/v1?pageNumber={pageNum}',
      pumpTypes: "/api/Baroid/RigEquipmentCatalog/v1/pumpTypes",
      screenTypes: "/api/Baroid/RigEquipmentCatalog/v1/screenLayouts",
      solids: "/api/Baroid/SolidsControlEquipmentCatalog/v1?pageNumber={pageNum}",
      surfaceEquipmentType: "/api/Baroid/SurfaceEquipmentTypeCatalog/v1",
      pumpPositions: "/api/Baroid/PumpCatalog/v1/position"
    },
    tubularCatalogs: {
      bitTypes: "/api/Baroid/TubularCatalog/v1/bitTypes",
      bypassStates: "/api/Baroid/TubularCatalog/v1/tubularComponents/properties/bypassStates",
      casingsLiners: '/api/Baroid/TubularCatalog/v1/casingsLiners',
      casingsLinerTypes: '/api/Baroid/TubularCatalog/v1/casingsLiners/casingLinerTypes',
      crossOverStates: "/api/Baroid/TubularCatalog/v1/tubularComponents/properties/crossOverStates",
      risers: '/api/Baroid/TubularCatalog/v1/risers',
      componentTypes: "/api/Baroid/TubularCatalog/v1/tubularComponents/tubularComponentTypes",
      components: "/api/Baroid/TubularCatalog/v1/tubularComponents",
    },
    //adding well header well purpose api end points
    wellCatalogs: {
      basins: "/api/Baroid/BasinCatalog/v1",
      counties: "/api/Baroid/CountryCatalog/v1/countries/{countryName}/counties",
      countries: "/api/Baroid/CountryCatalog/v1/countries",
      regioncountries: "/api/Baroid/RegionCatalog/v1/regions/{regionName}/countries",
      regions: "/api/Baroid/RegionCatalog/v1/regions",
      regionscountries: "/api/Baroid/RegionCatalog/v1",
      statecounties: "/api/Baroid/CountryCatalog/v1/countries/{countryName}/states/{stateName}/counties",
      states: "/api/Baroid/CountryCatalog/v1/countries/{countryName}/states",
      timeZones: "/api/Baroid/TimezoneCatalog/v1",
      wellPurpose: "/api/Baroid/WellCatalog/v1/wellPurpose",
      wellStatus: "/api/Baroid/WellCatalog/v1/wellStatus",
      wellType: "/api/Baroid/WellCatalog/v1/wellType",
    },
    analysis: {
      type: "/api/Baroid/analysisCatalog/v1/type",
      status: "/api/Baroid/analysisCatalog/v1/status",
      reason: "/api/Baroid/analysisCatalog/v1/reason",
    },
    analysisCase: {
      reason: "/api/Baroid/analysisCaseCatalog/v1/reason",
    },
    unitProfiles: {
      list: "/api/Baroid/UnitProfileCatalog/v1"
    }
  },

  bhaRuns: {
    getByWellId: "/api/Baroid/wells/v1/{wellId}/bhaRuns",
    getFullByWellId: "/api/Baroid/wells/v1/{wellId}/bhaRunsIncludeTubularInfo",
    getbyBHARunId: "/api/Baroid/wells/v1/bhaRuns/{bhaRunId}",
    updateBHARun: "/api/Baroid/wells/v1/bhaRuns/createUpdate",
    deleteBHARun: "/api/Baroid/wells/v1/bhaRuns/{bhaRunId}/delete"
  },

  cases: {
    list: "/api/v1/jobs/{jobId}/cases",
    post: "api/v1/jobs/{jobId}/cases",
    get: "api/v1/jobs/{jobId}/cases/{caseId}",
    delete: "/api/v1/jobs/{jobId}/cases/{caseId}",
    update: "/api/v1/jobs/{jobId}/cases",
    getCaseByCaseId: "/api/v1/jobs/{jobId}/cases/{caseId}/scenarios",
  },

  communications: {
    get: "/api/v1/jobs/{jobId}/communications",
    delete: "/api/v1/jobs/{jobId}/communications/{userId}",
  },

  collapsedGradients: {
    get: "/api/v1/jobs/{jobId}/collapsegradients?componentId={componentId}",
    post: "/api/v1/jobs/{jobId}/collapsegradients?componentId={componentId}",
    delete: "/api/v1/jobs/{jobId}/collapsegradients/{idx}?componentId={componentId}",
    deleteAll: "/api/v1/jobs/{jobId}/collapsegradients?componentId={componentId}",
  },

  jobs: {
    authenticator: '/api/Baroid/jobs/v1/{jobId}/tightHole/{userId}',
    active: "/api/v1/jobs/active",
    addToDashboard: "/api/v1/jobs/addToDashboard",
    get: "/api/Baroid/jobs/v1",
    getCloud: "/api/Baroid/jobSync/v1",
    getById: "/api/Baroid/jobs/v1/{jobId}",
    packet: "/api/baroid/jobPackets/v1/{jobId}",
    packetEncrypt: "/api/baroid/jobPackets/v1/encrypt",
    clone: "/api/baroid/jobPackets/v1/cloneInternal",
    cloneActual: "/api/baroid/jobPackets/v1/cloneActualJob",
    clonePlanning: "/api/baroid/jobPackets/v1/clonePlanningJob",
    compare: "/api/baroid/jobPackets/v1/compare",
    patch: "/api/v1/jobs/{jobId}",
    post: "/api/Baroid/jobs/v1/createUpdate",
    postDuplicatet: "/api/v1/jobs/createFromExisting",
    put: "/api/Baroid/jobs/v1/createUpdate",
    search: "/api/v1/jobs/search?{filter}",
    searchSortPage: "/api/Baroid/jobs/v1/searchSortPage",
    searchJobs: "/api/Baroid/jobs/v1/search",
    transferJob: "/api/Baroid/jobSync/v1/sync",
    getByJobIds: "/api/Baroid/jobs/v1/getByIds",
    jobIsWellSightUsed: '/api/Baroid/jobs/v1/iswellsightused/{jobId}',
    setSeparationSolutionsOnly: '/api/Baroid/jobs/v1/separationSolutionsOnly/{jobId}',
    syncIdos: '/api/Baroid/jobs/v1/iDos/sync',
    searchSortPageIDos: '/api/Baroid/jobs/v1/iDos/searchSortPage'
  },

  projects: {
    searchProjects: "/api/Baroid/projects/v1/search",
    searchSortPage: "/api/Baroid/projects/v1/searchSortPage",
    getByProjectIds: "/api/Baroid/projects/v1/getByIds"
  },

  fann75: {
    validateFann75JSON: '/api/Baroid/fann75/v1/validate',
  },

  fractureGradients: {
    get: "/api/Baroid/wells/v1/{wellId}/fractureGradients",
    post: "/api/Baroid/wells/v1/fractureGradients/createUpdate",
  },

  fluid_sets: {
    createOrUpdateFluidSet: '/api/Baroid/fluidSets/v1/createUpdate',
    deleteFluidSet: '/api/Baroid/fluidSets/v1/{fluidSetId}/delete',
    getAllFluidSets: '/api/Baroid/fluidSets/v1',
    getFluidSetById: '/api/Baroid/fluidSets/v1/{fluidSetId}',
    getFluidSetsByJobId: '/api/Baroid/jobs/{jobId}/fluidSets/v1',
  },

  fluids: {
    getFluidSets: "/api/Baroid/fluidSets/v1",  //getfluidsetsdata
    get: "/api/Baroid/jobs/{jobId}/fluidSets/v1",
    post: "/api/Baroid/fluidSets/v1/createUpdate",
    calculator: "/api/v1/jobs/{jobId}/fluidproperties/fluidcalculator",
    delete: "/api/Baroid/fluidSets/v1/{fluidSetId}/delete"
  },

  fractures: {
    get: "/api/v1/jobs/{jobId}/fracturegradients?componentId={componentId}",
    post: "/api/v1/jobs/{jobId}/fracturegradients?componentId={componentId}",
    delete: "/api/v1/jobs/{jobId}/fracturegradients/{idx}?componentId={componentId}",
    deleteAll: "/api/v1/jobs/{jobId}/fracturegradients?componentId={componentId}",
  },

  horizontalStress: {
    get: "/api/v1/jobs/{jobId}/minimumhorizontalgradients?componentId={componentId}",
    post: "/api/v1/jobs/{jobId}/minimumhorizontalgradients?componentId={componentId}",
    delete: "/api/v1/jobs/{jobId}/minimumhorizontalgradients/{idx}?componentId={componentId}",
    deleteAll: "/api/v1/jobs/{jobId}/minimumhorizontalgradients?componentId={componentId}",
  },

  hydraulics: {
    getHydraulicsInputById: '/api/Baroid/Hydraulics/v1/WellHydraulics/HydraulicsRequest/{requestId}',
    calculateHydraulicsInput: '/api/Baroid/Hydraulics/v1/WellHydraulics/Calculate',
    postHydraulicsOutput: '/api/Baroid/Hydraulics/v1/WellHydraulics/HydraulicsOutputs',
    postHydraulicsInput: '/api/Baroid/Hydraulics/v1/WSHydraulics/HydraulicsRequest',
    getHydraulicsOutputById: '/api/Baroid/Hydraulics/v1/WellHydraulics/HydraulicsOutputs/{requestId}',
    calculatePumpPerformance: '/api/Baroid/Hydraulics/v1/PumpPerformance'
  },

  intervals: {
    getIntervalById: '/api/Baroid/wells/v1/intervals/{intervalId}',
    getIntervalsByWellId: '/api/Baroid/wells/v1/{wellId}/intervals',
    getIntervalsIncludeGeometryByWellId: '/api/Baroid/wells/v1/{wellId}/intervalsIncludeGeometry',
    createOrUpdateInterval: '/api/Baroid/wells/v1/intervals/createUpdate',
    createOrUpdateManyIntervals: '/api/Baroid/wells/v1/intervals/createUpdateMany',
    deleteInterval: '/api/Baroid/wells/v1/intervals/{intervalId}/delete',
    copyIntervals: '/api/Baroid/wells/v1/intervals/copy',
  },

  lithostratigraphies: {
    get: "/api/Baroid/wells/v1/{wellId}/lithostratigraphy ",
    post: "/api/Baroid/wells/v1/lithostratigraphy/createUpdate ",
    delete: "/api/Baroid/wells/v1/lithostratigraphy/lithostratigraphyStations/{lithostratigraphyStationId}/delete",
    lithostratigraphiesStations: {
      get: "/api/Baroid/wells/v1/lithostratigraphy/{lithostratigraphyId}/lithostratigraphyStations",
      post: "/api/Baroid/wells/v1/lithostratigraphy/LithostratigraphyStations/createUpdate",
    }
  },

  mudPump: {
    getByRigId: "/api/Baroid/rigs/v1/{rigId}/mudPumps",
    post: "/api/Baroid/rigs/v1/mudPumps/createUpdate",
    delete: "/api/Baroid/rigs/v1/mudPumps/{Id}/delete",
  },

  mudLiftPump: {
    getByRigId: "/api/Baroid/rigs/v1/{rigId}/mudLiftPumps",
    post: "/api/Baroid/rigs/v1/mudLiftPump/createUpdate",
    delete: "/api/Baroid/rigs/v1/mudLiftPump/{Id}/delete",
  },

  personnel: {
    personnel: "/api/v1/jobs/{jobId}/teams",
    delete: "/api/v1/jobs/{jobId}/teams",
    getPersonnelData: "/api/v1/team",
  },

  pits: {
    get: "/api/Baroid/rigs/v1/pits",
    getByRigId: "/api/Baroid/rigs/v1/{rigId}/pits",
    getByPitId: "/api/Baroid/rigs/v1/pits/{Id}",
    post: "/api/Baroid/rigs/v1/pits/createUpdate",
    delete: "/api/Baroid/rigs/v1/pits/{Id}/delete",
    postMany: "/api/Baroid/rigs/v1/pits/createUpdateMany",
    deleteMany: "/api/Baroid/rigs/v1/pits/deleteMany",
  },

  porePressure: {
    get: "/api/v1/jobs/{jobId}/porepressuregradients?componentId={componentId}",
    post: "/api/v1/jobs/{jobId}/porepressuregradients?componentId={componentId}",
    delete: "/api/v1/jobs/{jobId}/porepressuregradients/{idx}?componentId={componentId}",
    deleteAll: "/api/v1/jobs/{jobId}/porepressuregradients?componentId={componentId}",
  },

  porePressureGradients: {
    get: "/api/Baroid/wells/v1/{wellId}/porePressureGradient",
    post: "/api/Baroid/wells/v1/porePressureGradient/createUpdate",
  },

  rheolory: {
    calculateBinghamPlasticRheology: '/api/Baroid/Rheology/v1/BinghamPlastic',
    calculatePowerLawRheology: '/api/Baroid/Rheology/v1/PowerLaw',
    calculateHerschelBulkleyRheology: '/api/Baroid/Rheology/v1/HerschelBulkley',
  },

  rigPackets: {
    getRigByRigId: '/api/baroid/rigPackets/v1/{rigId}'
  },

  rigs: {
    get: "/api/Baroid/jobs/v1/{jobId}/rigs",
    post: "/api/Baroid/rigs/v1/createUpdate",
    search: "/api/v1/rigs/search?{filter}",
    searchByRigName: "/api/Baroid/rigs/v1/{rigName}/rig",
    searchByRigId: "/api/Baroid/rigs/v1/{rigId}",
    searchByWellName: "/api/Baroid/wells/v1/{wellName}/rig",
    searchByContractor: "/api/Baroid/rigs/v1/{contractor}/rigs",
    searchByOperatorName: "/api/Baroid/wells/v1/{operatorName}/rigs",
    searchByParams: "/api/Baroid/rigs/v1/search",
    searchAll: "/api/Baroid/rigs/v1"
  },

  sales: {
    get: "/api/Baroid/jobs/v1/salesInformation",
    put: "/api/Baroid/jobs/v1/salesInformation/createUpdate",
    getById: "/api/Baroid/jobs/v1/{jobId}/salesInformation",
    post: "/api/Baroid/jobs/v1/salesInformation/createUpdate",
  },

  serviceLines: {
    getByRigId: "/api/Baroid/rigs/v1/{rigId}/serviceLines",
    post: "/api/Baroid/rigs/v1/serviceLines/createUpdate",
    delete: "/api/Baroid/rigs/v1/serviceLines/{Id}/delete",
  },

  solidsControlEquipment: {
    getByRigId: "/api/Baroid/rigs/v1/{rigId}/solidsControlEquipment",
    post: "/api/Baroid/rigs/v1/solidsControlEquipment/createUpdate",
    delete: "/api/Baroid/rigs/v1/solidsControlEquipment/{sceStyle}/{sceTypeId}/delete",
  },

  surfaceEquipment: {
    getByRigId: "/api/Baroid/rigs/v1/{rigId}/surfaceEquipment",
    post: "/api/Baroid/rigs/v1/surfaceEquipment/createUpdate",
    delete: "/api/Baroid/rigs/v1/surfaceEquipment/{Id}/delete",
  },

  survey: {
    calculateTVD: '/api/Baroid/Survey/v1/Tvd',
  },

  target_properties: {
    getGeneralProperties: '/api/Baroid/wells/v1/intervals/targetSets/{targetSetId}/targetProperties/generalProperties',
    getNAFProperties: '/api/Baroid/wells/v1/intervals/targetSets/{targetSetId}/targetProperties/nafProperties',
    getWBMProperties: '/api/Baroid/wells/v1/intervals/targetSets/{targetSetId}/targetProperties/wbmProperties',
    getAdditionalProperties: '/api/Baroid/wells/v1/intervals/targetSets/{targetSetId}/targetProperties/additionalProperties',
    createOrUpdateTargetProperty: '/api/Baroid/wells/v1/intervals/targetSets/targetProperties/createUpdate',
    deleteTargetProperty: '/api/Baroid/wells/v1/intervals/targetSets/targetProperties/{targetPropertyId}/delete',
  },

  target_sets: {
    getTargetSetById: '/api/Baroid/wells/v1/intervals/targetSets/{targetSetId}',
    getTargetSetsByIntervalId: '/api/Baroid/wells/v1/intervals/{intervalId}/targetSets',
    createOrUpdateTargetSet: '/api/Baroid/wells/v1/intervals/targetSets/createUpdate',
    deleteTargetSet: '/api/Baroid/wells/v1/intervals/targetSets/{targetSetId}/delete',
  },

  teams: {
    createTeams: "/api/v1/jobs/{jobId}/teams",
    deleteTeams: "/api/v1/jobs/{jobId}/teams",
    updateTeams: "/api/v1/jobs/{jobId}/teams/{teamId}",
    copyTeamFromJob: "/api/v1/jobs/{jobId}/copyTeamFromJob",
    getTeamDataDetails: "/api/v1/jobs/{jobId}/teams",
  },

  temperatureProfiles: {
    get: "/api/Baroid/wells/v1/{wellId}/thermalGradients",
    post: "/api/Baroid/wells/v1/thermalGradients/createUpdate",
    delete: "/api/Baroid/wells/v1/thermalGradients/{thermalGradientId}/delete"
  },

  toolString: {
    get: "/api/v1/jobs/{jobId}/toolstrings?componentId={componentId}",
    post: "/api/v1/jobs/{jobId}/toolstrings?componentId={componentId}",
    component: "/api/v1/toolstrings/components/{componentTypeId}?componentId={componentId}",
    delete: "/api/v1/jobs/{jobId}/toolstrings/{toolstringId}?componentId={componentId}",
  },

  trajectories: {
    get: "/api/Baroid/wells/v1/{wellId}/trajectory",
    getByWellboreId: "/api/Baroid/wells/v1/wellbore/{wellboreId}/trajectory",
    post: "/api/Baroid/wells/v1/trajectory/createUpdate",
    delete: "/api/v1/jobs/{jobId}/trajectories/{idx}?componentId={componentId}",
    deleteAll: "/api/v1/jobs/{jobId}/trajectories/delete?componentId={componentId}",
    getNorthingEasting: "/api/v1/jobs/{jobId}/trajectories/northingeastingcurve?componentId={componentId}",
    getTvdVertical: "/api/v1/jobs/{jobId}/trajectories/tvdverticalsectioncurve?componentId={componentId}",
    postImport: "/api/v1/jobs/{jobId}/trajectories/import?componentId={componentId}",
    synchronizeTrajectory: "/api/Baroid/wells/v1/wellbores/{wellboreId}/synchronizeTrajectory",
    synchronizeTrajectoryMode: "/api/Baroid/wells/v1/wellbores/{wellboreId}/synchronizeTrajectoryMode",
    trajectoryStation: {
      get: "/api/Baroid/wells/v1/trajectory/{trajectoryId}/trajectoryStations",
      post: "/api/Baroid/wells/v1/trajectory/trajectoryStations/createUpdate",
      postMany: "/api/Baroid/wells/v1/trajectory/trajectoryStations/createUpdateMany",
      delete: "/api/Baroid/wells/v1/trajectory/trajectoryStations/{trajectoryStationId}/delete",
      deleteMany: "/api/Baroid/wells/v1/trajectory/trajectoryStations/deleteMany",
    },
    copyTrajectory: "/api/Baroid/wells/v1/trajectory/copy"
  },

  tubulars: {
    get: "/api/Baroid/wells/v1/bhaRuns/tubulars/{tubularId}/tubularComponents",
    post: "/api/Baroid/wells/v1/bhaRuns/tubularComponents/createUpdate",
    createUpdateMany: "/api/Baroid/wells/v1/bhaRuns/tubularComponents/createUpdateMany",
    deleteMany: "/api/Baroid/wells/v1/bhaRuns/tubularComponents/deleteMany",
  },

  tubularComponents: {
    get: "/api/Baroid/wells/v1/bhaRuns/tubulars/tubularComponents/{tubularComponentId}",
    post: "/api/Baroid/wells/v1/bhaRuns/tubularComponents/{tubularComponentId}/delete",
    nozzles: {
      post: "/api/Baroid/wells/v1/bhaRuns/tubularComponents/{tubularComponentId}/nozzles/createUpdate"
    }
  },

  user: {
    getAllProfileSettings: "/api/Baroid/profileSettings/v1",
    getProfileSettings: "/api/Baroid/profileSettings/v1/user/{userId}",
    postProfileSettings: "/api/Baroid/profileSettings/v1/createUpdate",
    getUnitSystems: "/api/Baroid/profileSettings/v1/user/{userId}/unitSystems",
    postActiveUserUnitSystem: "/api/Baroid/profileSettings/v1/user/unitSystems/setActive",
    postActiveJobUnitSystem: "/api/Baroid/profileSettings/v1/user/unitSystems/jobs/setActive",
    getLastVisitedJob: "/api/Baroid/profileSettings/v1/user/{userId}/lastVisitedJob",
    postLastVisitedJob: "/api/Baroid/profileSettings/v1/user/lastVisitedJob/createUpdate",
    postUserRole: "/api/Baroid/profileSettings/v1/updateRole"
  },

  unitSystem: {
    getUnitSystems: "/api/Baroid/UnitSystems/v1",
    postCustomUnitSystem: "/api/Baroid/UnitSystems/v1/BaraLogix/CustomUnitSystem/CreateUpdate",
    deleteUnitSystem: "/api/Baroid/UnitSystems/v1/delete?unitSystemId={unitSystemId}&unitSystemUserId={unitSystemUserId}",
    getUnitSystemsBySystemId: "/api/Baroid/UnitSystems/v1/{unitSystemId}",
  },

  uom: {
    getColumnUnitSystems: "/unitapi/v1/users/Settings/componentunitsettings/filter?jobId={jobId}&componentId={componentId}&fieldId={fieldId}&quantityType={quantityType}&unit={unit}&sampleValue={sampleValue}",
    postColumnUnitSystems: "/unitapi/v1/users/Settings/componentunitsettings",
  },

  volumes: {
    HorizontalCylindrical: "/api/Baroid/Volume/v1/PitVolume/HorizontalCylindrical",
    HorizontalCylindricalEllipticalEnds: "/api/Baroid/Volume/v1/PitVolume/HorizontalCylindricalEllipticalEnds",
    HorizontalCylindricalSphericalEnds: "/api/Baroid/Volume/v1/PitVolume/HorizontalCylindricalSphericalEnds",
    Rectangular: "/api/Baroid/Volume/v1/PitVolume/Rectangular",
    VerticalCylindrical: "/api/Baroid/Volume/v1/PitVolume/VerticalCylindrical",
    VerticalCylindricalConicBottom: "/api/Baroid/Volume/v1/PitVolume/VerticalCylindricalConicalBottom",
    VerticalCylindricalRoundBottom: "/api/Baroid/Volume/v1/PitVolume/VerticalCylindricalRoundBottom",
  },

  wells: {
    get: "/api/v1/wells",
    getByJobId: "/api/Baroid/jobs/{jobId}/wells/v1",
    getByWellId:"/api/Baroid/wells/v1/{wellId}",
    post: "/api/Baroid/wells/v1/createUpdate",
    search: "/api/v1/wells/search?{filter}",
  },

  wellHeader: {
    get: "/api/Baroid/jobs/{jobId}/wells/v1",
    post: "/api/v1/jobs/{jobId}/wells",
    updateWellHeader: "/api/Baroid/wells/v1/createUpdate"
  },

  wellboreDiagram: {
    getWellboreDefaultData: "/assets/data/wellbore/defaultData.json",
    getPolygonMacros: "/assets/data/wellbore/polygon-macros.json",
  },

  wellbores: {
    getWellboreById: "/api/Baroid/wells/v1/wellbores/{wellboreId}",
    createOrUpdateWellbore: "/api/Baroid/wells/v1/wellbores/createUpdate",
    deleteWellbore: "/api/Baroid/wells/v1/wellbores/{wellboreId}/delete",
    getWellboresByWellId: '/api/Baroid/wells/v1/{wellId}/wellbores',
    getWellboresByWellIdIncludeGeometry: '/api/Baroid/wells/v1/{wellId}/wellboresIncludeGeometry',
    getWellboreType: '/api/Baroid/TubularCatalog/v1/wellboreTypes',
    getAssociatedIntervals: '/api/Baroid/wells/v1/wellbores/{wellboreId}/getIntervalsAssociation',
    createOrUpdateWellboreMode: '/api/Baroid/wells/v1/wellbores/createUpdateSynchronize/{mode}'
  },

  wellboreGeometry: {
    post: "/api/Baroid/wells/v1/wellbores/wellboreGeometrySection/createUpdate",
    delete: "/api/Baroid/wells/v1/wellbores/wellboreGeometrySection/{wellboreGeometrySectionId}/delete",
    casings: {
      getByWellboreId: "/api/Baroid/wells/v1/wellbores/{wellboreId}/wellboreGeometrySections/casingsLiners",
    },
    risers: {
      getByWellboreId: "/api/Baroid/wells/v1/wellbores/{wellboreId}/wellboreGeometrySections/risers",
    },
    openholes: {
      getByWellboreId: "/api/Baroid/wells/v1/wellbores/{wellboreId}/wellboreGeometrySections/openHoles",
    },
    thermalGradients: {
      get: "/api/Baroid/wells/v1/{wellId}/thermalGradients ",
      post: "/api/Baroid/wells/v1/thermalGradients/createUpdate ",
      delete: "/api/Baroid/wells/v1/thermalGradients/{thermalGradientId}/delete"
    },
  },

  risers: {
    get: "/api/v1/jobs/{jobId}/risers?componentId={componentId}",
    post: "/api/v1/jobs/{jobId}/risers?componentId={componentId}",
    PostRiser: "/api/Baroid/wells/v1/wellbores/wellboreGeometrySection/createUpdate",
    deleteRiser: "/api/Baroid/wells/v1/wellbores/wellboreGeometrySection/{wellboreGeometrySectionId}/delete",
    delete: "/api/v1/jobs/{jobId}/risers/{idx}?componentId={componentId}",
    getByWellboreId: "/api/Baroid/wells/v1/wellbores/{wellboreId}/wellboreGeometrySections/risers"
  },

  openHoles: {
    get: "/api/v1/jobs/{jobId}/holesections?componentId={componentId}",
    post: "/api/v1/jobs/{jobId}/holesections?componentId={componentId}",
    delete: "/api/v1/jobs/{jobId}/holesections/{idx}?componentId={componentId}",
    getByWellboreId: "/api/Baroid/wells/v1/wellbores/{wellboreId}/wellboreGeometrySections/openHoles",
    PostopenHoles: "/api/Baroid/wells/v1/wellbores/wellboreGeometrySection/createUpdate",
    deleteopenHoles: "/api/Baroid/wells/v1/wellbores/wellboreGeometrySection/{wellboreGeometrySectionId}/delete",
  },

  casings: {
    get: "/api/v1/jobs/{jobId}/casings?componentId={componentId}",
    post: "/api/v1/jobs/{jobId}/casings?componentId={componentId}",
    delete: "/api/v1/jobs/{jobId}/casings/{idx}?componentId={componentId}",
    getByWellboreId: "/api/Baroid/wells/v1/wellbores/{wellboreId}/wellboreGeometrySections/casingsLiners",
  },

  formation:{
    copyFormation: '/api/Baroid/wells/v1/formation/copy',
  },

  analysis: {
    getAllAnalysis: '/api/Baroid/jobs/{jobId}/analysis/v1',
    getById: '/api/Baroid/analysis/v1/{analysisId}',
    createOrUpdate: '/api/Baroid/analysis/v1/createUpdate',
    createAutomation: '/api/Baroid/analysis/v1/createAutomation',
    delete: '/api/Baroid/analysis/v1/{analysisId}/delete',
    copy: '/api/Baroid/analysis/v1/copy',
    transformToAnalysisCase: '/api/Baroid/analysis/v1/transformAndCreateFromHydraulicRequest'
  },

  reportManagement: {
    createOrUpdate: '/api/Baroid/reportManagements/v1/createUpdate',
    getByJobId: '/api/Baroid/reportManagements/v1/getbyJobId/{jobId}'
  },

  analysisCase: {
    getByAnalysisId: '/api/Baroid/analysis/{analysisId}/analysisCase/v1',
    getByAnalysisCaseId: '/api/Baroid/internalAnalysisCase/v1/{analysisCaseId}',
    getByRequestId: '/api/Baroid/analysisCase/v1/getByRequestId/{requestId}',
    getIntervalPackets: '/api/Baroid/jobs/v1/{jobId}/intervalPackets/{intervalId}',
    createOrUpdateAnalysisCase: '/api/Baroid/analysisCase/v1/createUpdate',
    deleteAnalysisCase: '/api/Baroid/analysisCase/v1/{analysisCaseId}/delete',
    buildHydraulics: '/api/Baroid/intervalPackets/v1/hydraulics',
    postCaseOutput: "/api/Baroid/Hydraulics/v1/WellHydraulics/HydraulicCasesOutputs",
    getCaseOutput: "/api/Baroid/Hydraulics/v1/WellHydraulics/HydraulicCasesOutputs/{analysisCaseId}",
    calculate: '/api/Baroid/Hydraulics/v1/WellHydraulics/CalculateHydraulicsCase',
  },

  systemStatus: {
    checkConnection: '/api/Baroid/Diagnostics/v1/NetworkStatus',
  },
  wellbore2D: {
    schematic: "/api/Baroid/Wellbore2D/v1/Schematic"
  }
};

<div *ngIf="errorMessageType?.type">
  <p *ngIf="errorMessageType?.type === 'required'" class="small error-text"> {{'errors.canNotBeEmpty' | translate}}</p>

  <p *ngIf="errorMessageType?.type === 'mustBeNumber'" class="small error-text"> {{'errors.mustBeNumeric' | translate}}</p>

  <p *ngIf="errorMessageType?.type === 'mustBeInteger'" class="small error-text"> {{'errors.mustBeInteger' | translate}}</p>

  <p *ngIf="errorMessageType?.type === 'negative'" class="small error-text">{{'errors.cannotBeNegative' | translate }}</p>

  <p *ngIf="errorMessageType?.type === 'nameIsExists'" class="small error-text">{{'errors.nameAlreadyExists' | translate: {'value': ('errors.control.' + errorMessageType.controlName) | translate} }}</p>

  <p *ngIf="errorMessageType?.type === 'greaterThanZero'" class="small error-text">{{'errors.mustBeGreaterThanZero' | translate}}</p>

  <p *ngIf="errorMessageType?.type === 'greaterThanValue'" class="small error-text">{{'errors.mustBeGreaterThan' | translate: {'value': controlName?.errors?.value} }}</p>

  <!-- <p *ngIf="errorMessageType.type === 'min'" class="small error-text">{{'errors.mustBeGreaterThanZero' | translate}}</p>-->

  <p *ngIf="errorMessageType?.type === 'mustBeAlphaNumeric'" class="small error-text">{{'errors.mustBeAlphaNumeric' | translate}}</p>

  <p *ngIf="errorMessageType?.type === 'mustBeAlphaNumericAnd'" class="small error-text bold">
    {{'errors.mustBeAlphaNumericAnd' | translate: {'value': errorMessageType?.value ? errorMessageType?.value :''} }}
  </p>

  <p *ngIf="errorMessageType?.type === 'maxLength'" class="small error-text">
    {{'errors.cannotBeLongerThanValue' | translate: {'value': errorMessageType?.value ? errorMessageType?.value :''} }}
  </p>

  <p *ngIf="errorMessageType?.type === 'maxValue'" class="small error-text">
    {{'errors.maxValue' | translate: {'value': errorMessageType?.value ? errorMessageType?.value :''} }}
  </p>

  <p *ngIf="errorMessageType?.type === 'duplicate'" class="small error-text">
    {{'errors.duplicate' | translate: {'value': ('errors.control.' + errorMessageType.controlName) | translate} }}
  </p>

  <p *ngIf="errorMessageType?.type === 'duplicateWithin'" class="small error-text">
    {{'errors.mustbeUnique' | translate: {'value': errorMessageType.value  | translate} }}
  </p>

  <p *ngIf="errorMessageType?.type === 'percentValue'" class="small error-text">{{'errors.percentValue' | translate: {'maxValue': controlName?.errors?.maxValue} }}</p>

  <p *ngIf="errorMessageType?.type === 'maxPercent'" class="small error-text">
    {{ 'errors.mustNotExceedPercent' | translate:{'maxPercent': errorMessageType?.value ? errorMessageType?.value : '' } }}
  </p>

  <p *ngIf="errorMessageType?.type === 'greaterThan'" class="small error-text">
    {{'errors.maxMustGreaterThanMin' | translate }}
  </p>

  <p *ngIf="errorMessageType?.type === 'equalToValue'" class="small error-text">
    {{'errors.mustBeEqualToValue' | translate:{'value': errorMessageType.value ?  errorMessageType.value : ''} }}
  </p>

  <p *ngIf="errorMessageType?.type === 'greater'" class="small error-text">
    {{'errors.greaterThanField'|translate}}{{('errors.control.' + errorMessageType.controlName)|translate}}
  </p>

  <p *ngIf="errorMessageType?.type === 'greaterOrEqual'" class="small error-text">
    {{'errors.greaterThanOrEqualField'|translate}}{{('errors.control.' + errorMessageType.controlName)|translate}}
  </p>

  <p *ngIf="errorMessageType?.type === 'lessThanOrEqual'" class="small error-text">
    {{'errors.lessThanOrEqualField'|translate}}{{('errors.control.' + errorMessageType.controlName)|translate}}
  </p>
  <!-- <p *ngIf="errorMessageType.type === 'greaterOrEqual'" class="small error-text">{{'errors.mustBeGreaterThanOrEqual' | 
  translate:{'value': errorMessageType.value ?  errorMessageType.value : ''} }}</p> -->

  <p *ngIf="errorMessageType?.type === 'overlap'" class="small error-text">
    {{'errors.cannotOverlap' | translate:{'value': errorMessageType?.overlapWith ? errorMessageType?.overlapWith :''} }}
  </p>

  <p *ngIf="errorMessageType?.type === 'notChained'" class="small error-text">
    {{'errors.mustBeChained' | translate}}
  </p>

  <p *ngIf="errorMessageType?.type === 'limit'" class="small error-text">
    {{'errors.mustBeWithinLimits'| translate:{
      'start': errorMessageType?.startValue,
      'end': errorMessageType?.endValue}
    }}
  </p>

  <p *ngIf="errorMessageType?.type === 'containChild'" class="small error-text">
    {{'errors.containChild'| translate:{
      'controlName': ('errors.control.' + errorMessageType.controlName)|translate,
      'childName': (errorMessageType?.childName)|translate}
    }}
  </p>

  <p *ngIf="errorMessageType?.type === 'mustBeInList'" class="small error-text"> {{'errors.mustBeOneOfTheOptions' | translate}}</p>
  <p *ngIf="errorMessageType?.type === 'apiError'" class="small error-text">{{errorMessageType?.errorMsg}}</p>
</div>
